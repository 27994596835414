declare global {
    interface Window {
        showTooltip: (event: any, headertext: string, subtleHeaderText: string, flatContent: string, items: string[]) => void;
        showTooltipWithValueBox: (event: any, headertext: string, valueboxText: string, valueboxPositive : boolean, flatContent: string) => void;
        showTooltipMiniature: (event: any, headertext: string, subtleHeadertext: string, flatContent: string, punktArray: string, miniatureId: string) => void;
        hideTooltip: () => void;
    }
}

interface ICustomtooltipSettings {
    tooltipContainerIdSelector: string;
}

interface IMiniature {
    id: string;
    svg: string;
}

export class CustomTooltipModule {
    private readonly tooltipContainerIdSelector: string;

    private tooltipIdSelector: string;
    private tooltipItemtemplateIdSelector: string;
    private headerSelector: string;
    private headerSecondSelector: string;
    private imageSelector: string;
    private contentSelector: string;

    private miniatures: IMiniature[];

    constructor(settings: ICustomtooltipSettings, miniatures: IMiniature[] = null) {
        this.tooltipContainerIdSelector = settings.tooltipContainerIdSelector;
        this.tooltipIdSelector = `${this.tooltipContainerIdSelector} .custom-tooltip`;
        this.tooltipItemtemplateIdSelector = `${this.tooltipContainerIdSelector} .custom-tooltip-split-content`;
        this.headerSelector = `${this.tooltipContainerIdSelector} .tooltip-header`;
        this.headerSecondSelector = `${this.tooltipContainerIdSelector} .tooltip-subtle-header`;
        this.imageSelector = `${this.tooltipContainerIdSelector} .tooltip-image-content`;
        this.contentSelector = `${this.tooltipContainerIdSelector} .tooltip-content-container`;
        this.miniatures = miniatures;
    }

    public init() {
        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
        this.showTooltipWithValueBox = this.showTooltipWithValueBox.bind(this);
        this.showTooltipMiniature = this.showTooltipMiniature.bind(this);

        window.showTooltip = this.showTooltip;
        window.hideTooltip = this.hideTooltip;
        window.showTooltipWithValueBox = this.showTooltipWithValueBox;
        window.showTooltipMiniature = this.showTooltipMiniature;
    }

    private showHeader(show: boolean) {
        if (show) {
            $(this.headerSelector).show();
            $(this.tooltipIdSelector).find("hr").show();
        } else {
            $(this.headerSelector).hide();
            $(this.tooltipIdSelector).find("hr").hide();
        }
    }

    private handleHeaderText(headerText : string) {
        if (headerText && $(this.headerSelector).is(":empty")) {
            $(this.headerSelector).append(headerText);
            this.showHeader(true);
        }

        if (!headerText) {
            this.showHeader(false);
        }
    }

    private handleMouseEvent(event : any, tooltip : JQuery<HTMLElement>) {
        if ($(window).width() - event.pageX < 650) {

            const width = tooltip.width();

            tooltip.css({
                left: event.pageX - width - 48,
                top: event.pageY + 16
            });
        } else {
            tooltip.css({
                left: event.pageX + 16,
                top: event.pageY + 16
            });
        }

        if ($(window).height() - event.pageY < 100) {
            const height = tooltip.height();
            tooltip.css({
                top: event.pageY - height - 48
            });
        } else {
            tooltip.css({
                top: event.pageY + 16
            });
        }
    }

    private showTooltip(event: any, headertext: string, subtleHeaderText: string, flatContent: string, items: string[]) {
        const tooltip = $(this.tooltipIdSelector);
        this.handleHeaderText(headertext);

        if (subtleHeaderText && $(this.headerSecondSelector).is(":empty")) {
            const value = Number(subtleHeaderText);
            if (value) {
                $(this.headerSecondSelector).addClass("value-box")
                if (value > 0) {
                    $(this.headerSecondSelector).addClass("green");
                } else {
                    $(this.headerSecondSelector).addClass("red");
                }
            } else {
                $(this.headerSecondSelector).addClass("subtle");
                $(this.headerSecondSelector).append(" - ");
            }

            $(this.headerSecondSelector).append(`${subtleHeaderText}`);
        }

        if ($(this.contentSelector).is(":empty")) {
            if (flatContent) {
                $(this.contentSelector).append(flatContent);
            } else if(items) {
                items.forEach((item: string) => {
                    const itemTemplate = $(this.tooltipItemtemplateIdSelector);
                    const punktContent = itemTemplate.children().first();

                    const leftdiv = punktContent.children().first();
                    leftdiv.append(item[0]);

                    const rightdiv = leftdiv.next();
                    rightdiv.append(item[1]);

                    $(this.contentSelector).append(itemTemplate.html());

                    leftdiv.empty();
                    rightdiv.empty();
                });
            }
        }

        this.handleMouseEvent(event, tooltip);

        tooltip.show();
    }

    private showTooltipWithValueBox (event: any, headertext: string, valueboxText: string, valueboxPositive : boolean, flatContent: string) {
        const tooltip = $(this.tooltipIdSelector);

        if (headertext && $(this.headerSelector).is(":empty")) {
            $(this.headerSelector).append(headertext);
            this.showHeader(true);
        }

        if (!headertext) {
            this.showHeader(false);
        }

        if (valueboxText && $(this.headerSecondSelector).is(":empty")) {
            $(this.headerSecondSelector).addClass("value-box").addClass("padding-left-s").addClass("padding-right-s")
            if (valueboxPositive) {
                $(this.headerSecondSelector).append(`${valueboxText}`);
                $(this.headerSecondSelector).addClass("green");
            } else if (valueboxPositive) {
                $(this.headerSecondSelector).append(`${valueboxText}`);
                $(this.headerSecondSelector).addClass("red");
            } else {
                let text: string;
                if(valueboxText.length > 0){
                   text = valueboxText;
                } else{
                    text = " - ";
                }
                $(this.headerSecondSelector).append(`${text}`);
            }
        }

        if ($(this.contentSelector).is(":empty")) {
            $(this.contentSelector).append(flatContent);
        }

        this.handleMouseEvent(event, tooltip);
        tooltip.show();
    }

    private showTooltipMiniature(event: any, headertext: string, subtleHeadertext: string, flatContent: string, punktArray: string, miniatureId: string)
    {
        const tooltip = $(this.tooltipIdSelector);
        tooltip.addClass("miniature-tooltip")

        if (headertext && $(this.headerSelector).is(":empty")) {
            $(this.headerSelector).append(headertext);
            this.showHeader(true);
        }
        if (!headertext) {
            this.showHeader(false);
        }

        if (subtleHeadertext && $(this.headerSecondSelector).is(":empty")) {
            $(this.headerSecondSelector).append(subtleHeadertext);
        }

        if(miniatureId && $(this.imageSelector).is(":empty"))
        {
            const miniature = this.miniatures.find(m => m.id === miniatureId);
            $(this.imageSelector).append(miniature.svg)
        }

        if ($(this.contentSelector).is(":empty")) {
            if (flatContent) {
                $(this.contentSelector).append(flatContent);
            } else {
                $(this.contentSelector).addClass("hidden");
            }
        }

        this.handleMouseEvent(event, tooltip);
        tooltip.show();
    }

    private removeCustomClasses(){
        $(this.tooltipIdSelector).removeClass("miniature-tooltip");
        $(this.headerSecondSelector).removeClass("subtle");
        $(this.headerSecondSelector).removeClass("value-box");
        $(this.headerSecondSelector).removeClass("red");
        $(this.headerSecondSelector).removeClass("green");
        $(this.contentSelector).removeClass("hidden");
    }

    private hideTooltip() {
        $(this.headerSelector).empty();
        $(this.headerSecondSelector).empty();
        $(this.contentSelector).empty();
        $(this.imageSelector).empty();
        this.removeCustomClasses();

        const tooltip = $(this.tooltipIdSelector);
        tooltip.hide();
    }
}



