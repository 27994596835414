import { AsyncValueUpdateModule } from "ditmer-embla";
import * as moment from "moment";
import { GarudaApi } from "../../infrastructure/garudaApi";

export class NoteModule {

    private readonly garudaApi: GarudaApi;

    constructor() {
        this.garudaApi = new GarudaApi();
    }

    private convertDatesToLocal() {
        $(".noteEditDate").each(function (index, element) {
            const utcDate = $(element).attr("utcDate");
            const localDate = moment(utcDate).local().format('LLL');
            $(element).text(localDate);
        })
    }

    public initNotes() {
        this.convertDatesToLocal();

        new AsyncValueUpdateModule().init("input[data-async-value-update-url], select[data-async-value-update-url], textarea[data-async-value-update-url]", {
            performValidation: true,
            getIdCallback: ($element) => {
                return $element.data("thread-id");
            },
            getAdditionalPostParametersCallback: ($element) => {

                const noteId = $element.data("note-id");
                const noteBody: string = $element.val() as string;
                const threadId: string = $element.data("thread-id") as string;
                const viewType: string = $element.data("view-type") as string;

                return {
                    noteId: noteId,
                    noteBody: noteBody,
                    threadId: threadId,
                    viewType: viewType,
                    "__RequestVerificationToken": $("input:hidden[name='__RequestVerificationToken']").val() as string
                };
            },
            updateCompletedCallback: ($element, successData, successTextStatus, jqXHR) => {
                const noteId = $element.data("note-id") as string;

                if (noteId === "" && successData.noteId !== 0) {
                    $element.data("note-id", successData.noteId);
                    $element.data("async-value-update-url", "/Note/UpdateNote");
                }
            }
        });
    }

    public async setNoteCount(profileId: string) {
        var noteCount = await this.garudaApi.getNoteCount(profileId);
        $(".note-count-container").html(noteCount);
    }
}



