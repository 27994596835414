export class UserRoleModel {

    constructor(roleId: string, role: string, selected: boolean, changeable: boolean) {
        this.roleId = roleId;
        this.role = role;
        this.selected = selected;
        this.changeable = changeable;
    }

    roleId: string;
    role: string;
    selected: boolean;
    changeable: boolean;
}
