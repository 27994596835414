import * as React from "react";

type SelectListComponentProps = {
    title: string;
    name?: string;
    items?: any;
    onChange: (e: any) => void;
}

type SelectListComponentState = {
    selectedItem: any;
}

export class SelectListComponent extends React.Component<SelectListComponentProps, SelectListComponentState> {

    constructor(props: Readonly<SelectListComponentProps>) {
        super(props);

        this.state = {
            selectedItem: null
        };
    }

    render() {
        return (<>
            <label className={"bold"}>{this.props.title}
                <span className="required-field">∗</span>
            </label>
            <ul className={"questionnaire-list"} id={this.props.name}>
                {this.props.children}
            </ul>
        </>);
    }
}
