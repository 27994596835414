import * as React from "react";
import { GarudaApi } from "../../../infrastructure/garudaApi";
import { RespondentModel } from "../../../models/profileCrud/RespondentModel";
import { CheckSettingModel, ToolCrudModel } from "../../../models/profileCrud/ToolCrudModel";
import { CheckLabelComponent } from "../../ui-components/CheckLabelComponent";
import { CheckDateComponent } from "../../ui-components/CheckDateComponent";
import { DateSettingModel } from "../../../models/settings/DateSettingModel";
import {settingsSelfEvaluation} from "../../../models/constants/settingsConstants";
import {Localization} from "../../../modules/localization-module";
import {ToolConstants, ToolEnum} from "../../../models/enums/toolEnum";

interface IProfileSettingProps {
    respondent: RespondentModel;
    selectSetting: (settingKey: string, value: string) => void;
    toolData: ToolCrudModel;
}

export class ProfileSettingComponent extends React.Component<IProfileSettingProps> {

    private garudaApi: GarudaApi;

    constructor(props: IProfileSettingProps) {
        super(props);

        this.garudaApi = new GarudaApi();
    }

    private renderCommonSettings() {
        const elements: JSX.Element[] = [];

        elements.push(
            <div key={`common-date-setting-${elements.length}`} className="col-md-12">
                <div className="row">
                    {
                        this.props.toolData.commonSettings.checkDates.map((checkDate: DateSettingModel, index: number) => {
                            const selectedSetting = this.props.respondent.settingValues.find(sv => sv.key == checkDate.key)
                            const value = selectedSetting === undefined ? "" : selectedSetting.value
                            return (
                                <div className={"col-lg-6"} key={checkDate.key}>
                                    <div className="form-group">
                                        <CheckDateComponent dateSelected={(e: string, val: string) => this.props.selectSetting(checkDate.key, val)}
                                                                label={checkDate.label}
                                                                value={value}
                                                                settingkey={checkDate.key}
                                                                disabled={this.props.respondent.isLocked()}
                                                                key={`general-setting-check-date-${index}`} />
                                    </div>
                                </div>);
                        })
                    }
                </div>
            </div>
        );

        elements.push(
            <div key={`common-check-setting-${elements.length}`} className="col-md-12">
                <div className="row">
                    {
                        this.props.toolData.commonSettings.checkLabels.map((checkLabel: CheckSettingModel, index: number) => {
                            const setting = this.props.respondent.settingValues.find(sv => sv.key == checkLabel.key);
                            if (setting) {
                                const value = setting.isChecked();
                                return (<CheckLabelComponent onSelected={(e) => this.props.selectSetting(checkLabel.key, e)}
                                                             label={checkLabel.label}
                                                             value={value}
                                                             settingkey={checkLabel.key}
                                                             licensed={true}
                                                             disabled={this.props.respondent.isLocked()}
                                                             key={`general-setting-check-label-${index}`} />);
                            }
                        })
                    }
                </div>
            </div>
        );

        return elements;
    }

    render() {
        return (
            <>
                {this.renderCommonSettings()}
            </>);
    }
}
