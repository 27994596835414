import {GarudaApi} from "../../infrastructure/garudaApi";
import {DropdownModule, NotificationModule} from "ditmer-embla";
import {NewUserSharingModel, NewUsersSharingModel} from "../../models/NewUsersSharingModel";
import {Localization} from "../localization-module";
import {UserRoleModel} from "../../models/users/UserRoleModel";
import {customizeSharing} from "../../profileSharingConstants";
import {inputDateFormat} from "../../models/constants/dateFormat";
import moment = require("moment");

interface IInviteUserModule {
    usersContainerSelector: string;
    usersProfileSharingUserSelector: string;
    usersProfileSharingUserInfoSelector: string;
    usersProfileSharingRoleSelector: string;
    usersEmailSelector: string;
    usersRoleSelectSelector: string;
    userInputSelector: string;
    userInputEmailSelector: string;
    userInputNameSelector: string;
    inviteUserBtnSelector: string;

    profileIds: string[],

    openCustomizeModalCallback: (email: string, name: string, userRoleModels: UserRoleModel[]) => void;
}

export class InviteUserModule {
    private readonly garudaApi: GarudaApi;
    private props: IInviteUserModule;
    private selectableRoleElements = ".selectable-roles";
    private selectableUserRoleModels: UserRoleModel[] = [];
    private savedUserSharingModel: NewUsersSharingModel;

    constructor(settings: IInviteUserModule) {
        this.props = settings;
        this.garudaApi = new GarudaApi();

        this.savedUserSharingModel = new NewUsersSharingModel(this.props.profileIds)
    }

    init() {
        const $this = this;

        $(this.selectableRoleElements).each(function() {
            const role = $(this).attr("role");
            const roleid = $(this).attr("roleid");
            const roleselected = $(this).attr("roleselected");
            const userRole = new UserRoleModel(roleid, role, roleselected === "True", true);

            if(!$this.selectableUserRoleModels.some(x => x.roleId === userRole.roleId)){
                $this.selectableUserRoleModels.push(userRole);
            }
        });

        $($this.props.userInputSelector).off("click").on("click", async function (e) {
            const email = $($this.props.userInputEmailSelector).val() as string;
            const name = $($this.props.userInputNameSelector).val() as string;
            const valid = $this.validateNewUser(email, name);

            if (valid) {
                await $this.SetNewUser($this, name, email);

                const role = $($this.props.usersProfileSharingRoleSelector).val() as string;
                $this.savedUserSharingModel.newUserSharingModels.push(new NewUserSharingModel(email, name, role));
            }
        });

        $($this.props.inviteUserBtnSelector).off("click").on("click", async function(e) {
            await $this.garudaApi.inviteNewUsers($this.savedUserSharingModel);

            $($this.props.usersContainerSelector).html("");
            $($this.props.inviteUserBtnSelector).attr("disabled", "disabled");

            $this.savedUserSharingModel.newUserSharingModels = [];

            NotificationModule.showSuccessSmall(Localization.getText("Invitation_Sent"));
        });
    }

    private async SetNewUser($this: any, name: string, email: string, timeLimit?: string)
    {
        const partialHtml = await $this.garudaApi.shareProfileWithNewUser($this.selectableUserRoleModels, email, name, timeLimit);
        $($this.props.usersContainerSelector).prepend(partialHtml);

        $($this.props.inviteUserBtnSelector).removeAttr("disabled");

        new DropdownModule().init($this.props.usersRoleSelectSelector, {
            searchable: false
        });

        $($this.props.usersRoleSelectSelector).off("select2:select").on("select2:select", function (e) {
            const roleId = e.params.data.id;
            $this.userId = $(this).data("user-id");
            const userEmail = $(this).data("user-email");
            const userName = $(this).data("user-name");

            if (roleId && roleId == customizeSharing) {
                $this.props.openCustomizeModalCallback(userEmail, userName, $this.selectableUserRoleModels);
            }
        });

        $($this.props.usersRoleSelectSelector).first().trigger("focus");
        $($this.props.userInputNameSelector).val("");
        $($this.props.userInputEmailSelector).val("");
    }

    private validateNewUser(email: string, name: string): boolean {
        const validName = this.validateNewUserName(name);
        const validEmail = this.validateNewUserEmail(email);

        return validName && validEmail;
    }

    private validateNewUserName(name: string) {
        const validName = name !== "";
        if (!validName) {
            NotificationModule.showErrorSmall(Localization.getText("Global_Name_Empty"));
            return false;
        }
        return true;
    }

    private validateNewUserEmail(email: string) {
        const validEmail = email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
        if (!validEmail) {
            NotificationModule.showErrorSmall(Localization.getText("Global_Email_Invalid"));
            return false;
        } else {
            const alreadyAddedEmails = $(this.props.usersEmailSelector).map(function () {
                return $.trim($(this).text());
            }).get();

            if (alreadyAddedEmails.some(em => em === email)) {
                NotificationModule.showErrorSmall(Localization.getText("User_Added_Error"));
                return false;
            }
        }
        return true;
    }

    public async AddSavedUsers()
    {
        if (this.savedUserSharingModel !== undefined)
        {
            for (const newUserSharingModel of this.savedUserSharingModel.newUserSharingModels) {
                this.SetNewUser(this, newUserSharingModel.name, newUserSharingModel.email, newUserSharingModel.timeLimit);
            }
        }
    }

    public GetTimeLimit(email: string, name: string): string {
        return this.savedUserSharingModel.newUserSharingModels.find(nu => nu.email === email && nu.name === name).timeLimit;
    }

    public SetTimeLimit(timeLimit: string | Date)
    {
        const name = $(this.props.usersProfileSharingUserInfoSelector)
            .children(".user-name").text().trim() as string;
        const email = $(this.props.usersProfileSharingUserInfoSelector)
            .children(".user-email").text().trim() as string;

        $.each(this.savedUserSharingModel.newUserSharingModels, function() {
            if (this.name === name && this.email === email)
            {
                this.timeLimit = moment(timeLimit, inputDateFormat, true).format()
            }
        })
    }
}
