import {Spinner} from "../spinner-module";

export class Modal {
    private readonly modalIdSelector: string;
    private readonly afterContent: (triggerElement: JQuery<HTMLElement>) => void;
    private readonly applyModal?: (dataUrl : string) => void;
    private readonly modalTrigger : JQuery<HTMLElement>;
    private asyncContent: (triggerElement: JQuery<HTMLElement>) => Promise<string>;
    private modalSelector : JQuery<HTMLElement>;
    private spinner: Spinner;
    private applySelector = "#modal-btn-apply";
    private applyUrl = "";

    constructor(modalTriggerselector: string,
                modalId: string,
                asyncContent: (triggerElement : JQuery<HTMLElement>) => Promise<string>,
                afterContent: (triggerElement : JQuery<HTMLElement>) => void,
                applyModal?: (dataUrl : string) => void) {
        this.applyModal = applyModal;
        this.modalTrigger = $(modalTriggerselector);
        this.modalSelector = $(modalId);
        this.modalIdSelector = modalId;
        this.asyncContent = asyncContent;
        this.afterContent = afterContent;

        this.spinner = new Spinner(`${this.modalIdSelector} .spinner-container`, `${this.modalIdSelector} .modal-body`);
    }

    public init(): void {
        const $this = this;

        if (this.modalTrigger) {
            this.modalTrigger.off("click").on("click", async function () {
                $this.modalSelector.modal("show");
                $this.applyUrl = $(this).data("url");
                await $this.loadModalContent($(this));
            });
        }

        if (this.applyModal) {
            $(this.modalIdSelector).find(this.applySelector).off("click").on("click", async function() {
                $this.showLoader();
                $this.applyModal($this.applyUrl);
            });
        }

        $(this.modalIdSelector).on('hidden.bs.modal', function () {
            $(this).find(".modal-body").empty();
        });
    }

    public showLoader() {
        this.spinner.showSpinner();
    }

    public async loadModalContent(triggerElement? : JQuery<HTMLElement>) {
        const modalBodySelector = `${this.modalIdSelector} .modal-body`;

        this.spinner.showSpinner();

        const contentHtml = await this.asyncContent(triggerElement);
        $(modalBodySelector).html(contentHtml);
        this.afterContent(triggerElement);

        this.spinner.hideSpinner();
    }

    public async loadNewContent(asyncContent: () => Promise<string>, afterContent?: () => void) {
        const modalBodySelector = `${this.modalIdSelector} .modal-body`;

        this.spinner.showSpinner();

        const contentHtml = await asyncContent();
        $(modalBodySelector).html(contentHtml);

        if(afterContent) {
            afterContent();
        }

        this.spinner.hideSpinner();
    }

    public open(): void {
        this.modalSelector.modal("show");
    }

    public close(): void {
        this.modalSelector.modal("hide");
    }

    public setTitle(title: string) {
        this.modalSelector.find(".modal-header").find(".modal-title").html(title);
    }

    public hideApply() {
        $(this.modalIdSelector).find(this.applySelector).hide();
    }

    public showApply() {
        $(this.modalIdSelector).find(this.applySelector).show();
    }
}
