export class SettingsValueModel {
    constructor(key: string, value: string, validate: boolean) {
        this.key = key;
        this.setValue(value);
        this.validate = validate;
    }

    key: string;
    value: string;
    validate: boolean;

    public isChecked(): boolean {
        if (this.value === "0") {
            return false;
        }

        return true;
    }

    public setValue(value: string) {
        if (value === "true") {
            this.value = "1"
        } else if (value === "false") {
            this.value = "0"
        } else {
            this.value = value;
        }
    }
}
