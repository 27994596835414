import { GarudaApi } from "../../infrastructure/garudaApi";
import { Spinner } from "../spinner-module";
import { NoteModule } from "../note-module"
import { ThreadNoteModule } from "../thread-note-module";

export interface GeneralNotesOptions {
    ProfileId?: string;
}


export class GeneralNotesModule {

    private readonly garudaApi: GarudaApi;
    private readonly noteModule: NoteModule;
    private readonly spinnerController: Spinner;

    private readonly noteModalSelector = $("#modal-note");
    private readonly modalNoteSelector = "#modal-note-container";
    private readonly noteModalBody = $(this.modalNoteSelector);
    private readonly btnNoteModalBtn = "#btn-open-note-modal";
    private readonly profileId: string;

    constructor(options: GeneralNotesOptions) {
        this.garudaApi = new GarudaApi();
        this.noteModule = new NoteModule();
        this.spinnerController = new Spinner("#modal-note-default-spinner", this.modalNoteSelector);

        this.profileId = options.ProfileId;
    }

    public async initialize() {
        $(this.btnNoteModalBtn).off("click").on("click", async () => {
            await this.initializeContent();
        });
    }

    private async initializeContent() {
        this.noteModule.setNoteCount(this.profileId);

        this.spinnerController.showSpinner();

        const noteModalBody = await this.garudaApi.getGeneralNotes(this.profileId);
        this.noteModalBody.html(noteModalBody);

        this.fitNotesToModal();

        this.noteModalSelector.modal("show");

        this.noteModule.initNotes();
        new ThreadNoteModule().initThreadNotes(() => this.initializeContent());

        this.spinnerController.hideSpinner();
    }

    private fitNotesToModal() {
        $(".note-avatar-container").removeClass("col-md-1").addClass("col-md-2");
        $(".note-content-container").removeClass("col-md-8").addClass("col-md-7");
    }

}
