import * as React from "react";
import {GarudaApi} from "../../../infrastructure/garudaApi";
import {Localization} from "../../../modules/localization-module";
import {DatetimePickerTypeEnum} from "ditmer-embla";
import {RespondentModel} from "../../../models/profileCrud/RespondentModel";
import {ToolCrudModel} from "../../../models/profileCrud/ToolCrudModel";
import {ProfilePurposeEnum} from "../../../models/enums/profilePurposeEnum";
import {DatePickerComponent} from "../../ui-components/DatePickerComponent";
import {PurposeSelectComponent} from "./PurposeSelectComponent";
import {settingsConstantsAnalysisPurpose} from "../../../infrastructure/constants";

interface IProfilePurposeProps {
    respondent: RespondentModel;
    selectSetting: (settingKey: string, value: string) => void;
    getSetting: (settingKey: string) => any;
    selectSpecialSettings: (settingKey: string, value: string) => void;
    toolData: ToolCrudModel;
}

const expectedDateOfEmploymentKey = "EXPECTED_DATE_OF_EMPLOYMENT";

export class ProfilePurposeComponent extends React.Component<IProfilePurposeProps> {
    private garudaApi: GarudaApi;
    private expectedDateOfEmployment: string;

    constructor(props: IProfilePurposeProps) {
        super(props);

        this.garudaApi = new GarudaApi();
        this.onPurposeSelected = this.onPurposeSelected.bind(this);
        this.expectedDateOfEmployment = this.getSpecialSettingValue(expectedDateOfEmploymentKey);
    }

    private onDateSelected(key: string, value: any) {
        this.props.selectSpecialSettings(key, value);
        this.expectedDateOfEmployment = value;
    }

    private onPurposeSelected(value?: string) {
        if(value === null || value !== ProfilePurposeEnum.Recruiting)
            this.props.selectSpecialSettings(expectedDateOfEmploymentKey, "");
        else {
            this.props.selectSpecialSettings(expectedDateOfEmploymentKey, this.expectedDateOfEmployment);
        }
    }

    private getSpecialSettingValue(settingKey: string) {
        const respondent = this.props.respondent;
        if (!respondent || !respondent.specialSettingValues)
            return;

        const settingsValueModel = this.props.respondent.specialSettingValues.find(k => k.key === settingKey);

        if (settingsValueModel === undefined)
            return;

        return settingsValueModel.value;
    }

    private renderPurpose() {
        const purposeComponent = (
            <div className={"form-group"}>
                <label className="bold" htmlFor={"purpose"}>{Localization.getText("PersonalProfile_Respondent_Purpose")}
                    <span className="required-field">∗</span>
                </label>
                <PurposeSelectComponent toolData={this.props.toolData}
                                        selectSetting={this.props.selectSetting}
                                        getSetting={this.props.getSetting}
                                        respondent={this.props.respondent}
                                        selectSpecialSettings={this.props.selectSpecialSettings}
                                        onPurposeSelected={this.onPurposeSelected}
                                        />
            </div>
        );

        const settingValue = this.props.getSetting(settingsConstantsAnalysisPurpose) as ProfilePurposeEnum;
        if (settingValue === ProfilePurposeEnum.Recruiting) {

            return (
                <>
                    {purposeComponent}
                    <div className={"form-group"}>
                        <DatePickerComponent calenderType={DatetimePickerTypeEnum.Date}
                                             label={Localization.getText("PersonalProfile_Expected_Date_Of_Employment")}
                                             value={this.expectedDateOfEmployment}
                                             disabled={false}
                                             allowClear={false}
                                             settingkey={expectedDateOfEmploymentKey}
                                             dateSelected={(k: string, v) => this.onDateSelected(expectedDateOfEmploymentKey, v)}
                        />
                    </div>
                </>
            );
        }

        return (
            <>
                {purposeComponent}
            </>
        );
    }

    public render() {
        return (<>
            {this.renderPurpose()}
        </>);
    }
}
