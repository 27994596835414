export enum SubToolEnum {
    None = 0,
    Job = 1,
    Mirror = 2,
    Reference = 3,
    Person = 4,
    BasisPerson = 11,
    FocusJob = 21,
    FocusMirror = 22,
    FocusReference = 23,
    FocusPerson = 24,
    FocusDreamPosition = 25,
    FocusGroup = 26,
    GarudalogicVersionA = 31,
    GarudalogicVersionB = 32,
    GarudalogicVersionC = 33,
    GarudalogicVersionD = 34,
    GarudalogicVersionA1 = 35,
    GarudalogicVersionB1 = 36,
    JobVsPersonal = 41,
    FocusJobVsPersonal = 42,
    FocusMirrorVsPersonal = 43,
    FocusDreamPositionVsPersonal = 44,
    MirrorVsPersonal = 45,
    ReferenceVsPersonal = 46,
    PersonalVsPersonal = 47,
    FocusPersonalVsPersonal = 48,
    ESportPerson = 51,
    ESportMirror = 52,
    FootballPerson = 61,
    GolfPerson = 71,
    GolfMirror = 72,
    HandballPerson = 81,
    HandballMirror = 82,
    GarudaMirror = 92,
    PreferencePerson = 101,
    PreferenceDreamposition = 102,
    PreferenceMirror = 103,
    SportPerson = 111,
    LeadersMentalScorecardPerson = 121,
    LeadersMentalScorecard = 122,
    LeadersMentalScorecardMirror = 123,
    Notes = 131
}
