import {Localization} from "../localization-module";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {ReportModule} from "../report-module";
import {Modal} from "../modal-module";
import {initializeEditTagsReactApp} from "../../editRespondentTags";
import { initializeCreateStaticGroupProfileReactApp } from "../../focusGroupCreateStaticProfileModal";
import {RespondentListModule} from "./respondentListModule";
import {MultiRespondentEditModel} from "../../models/tags/MultiRespondentEditModel";
import {TagsModel} from "../../models/tags/TagsModel";
import {PluralityEnum} from "../../models/enums/pluralityEnum";
import {ToolEnum} from "../../models/enums/toolEnum";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {EditRespondentModalModule} from "./editRespondentModalModule";
import {RespondentMiniProfileModalModule} from "./respondentMiniProfileModalModule";
import {NotificationModule} from "ditmer-embla";
import {ProfileSelectorModule} from "./respondentProfileSelectorModule";
import {FocusGroupProfileEditModal} from "../../focusGroupProfileEditModal";
import {focusGroupProfileListUrl} from "../../models/constants/apiConstants"
import {GrafModule} from "../graf-module";
import {GraphicTypeEnum} from "../../models/enums/graphTypeEnum";

export class RespondentActions {
    private editTagsModal: Modal;
    private deletePersonsModal: Modal;
    private archivePersonsModal: Modal;
    private withdrawConsentPersonsModal: Modal;
    private duplicateProfileModal: Modal;
    private createStaticGroupProfileModal: Modal;
    private graphIQModal: Modal;
    private miniProfileModule: RespondentMiniProfileModalModule;
    private profileSelectorModule: ProfileSelectorModule;
    private focusGroupProfileEditModal: FocusGroupProfileEditModal;

    private readonly garudaApi: GarudaApi;
    private readonly reportModule: ReportModule;
    private readonly editRespondentAction :EditRespondentModalModule

    constructor(toolEnum : ToolEnum, subtool : SubToolEnum) {
        this.garudaApi = new GarudaApi();
        this.reportModule = new ReportModule({
            openButtonSelector: ".generate-rapport-respondent",
            tool: toolEnum,
            subtool: subtool,
            outerCustomTooltipSelector: "#custom-tooltip-container"
        });
        this.focusGroupProfileEditModal = new FocusGroupProfileEditModal();

        this.editRespondentAction = new EditRespondentModalModule();
    }

    public initClickableRow() {
        const clickableElement = $(".clickable");
        const useMiniprofile = clickableElement.data("miniprofile");
        const useProfileSelector = clickableElement.data("selectprofile");

        if (useMiniprofile === "True"){
            const getProfileId = (clickedElement: JQuery<HTMLElement>) => {
                return clickedElement.parent("tr").data("profile-id");
            }

            this.miniProfileModule = new RespondentMiniProfileModalModule(".clickable", "#mini-profile-modal", getProfileId);
            this.miniProfileModule.initMiniProfileModal();
        } else if (useProfileSelector === "True") {
            const getRespondentIdCallback = (clickedElement: JQuery<HTMLElement>) => {
                return clickedElement.parent("tr").data("respondent-id");
            }

            this.profileSelectorModule = new ProfileSelectorModule(".clickable", "#profile-selector-modal", getRespondentIdCallback);
            this.profileSelectorModule.initModal();
        }
        else {
            clickableElement.off("click").on("click", async function () {
                window.location.href = $(this).data("url");
            });
        }
    }

    public initDelete(chosenProfilesIds: () => string[],
                      currentTabel: DataTables.Api) {
        const $this = this;
        const modalSelector = "#delete-profile-modal";

        $this.deletePersonsModal = new Modal(
            ".delete-persons",
            modalSelector,
            (triggerElement : JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("PersonalProfile_DeleteModal_Title"), currentTabel, triggerElement),
            () => null,
            () => {
                this.garudaApi.deleteRespondent(chosenProfilesIds()).then(() => {
                    window.location.reload();
                });
            }
        );

        $this.deletePersonsModal.init();
    }

    public initDuplicate(chosenProfilesIds: () => string[],
        currentTabel: DataTables.Api) {
        const $this = this;
        const modalSelector = "#duplicate-profile-modal";


        $this.duplicateProfileModal = new Modal(
            ".duplicate-questionary",
            modalSelector,
            (triggerElement: JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("DuplicateProfileModal_Title"), currentTabel, triggerElement),
            () => null,
            () => {
                this.garudaApi.duplicateProfilePost(chosenProfilesIds()).then(() => {
                    window.location.reload();
                });
            }
        );

        $this.duplicateProfileModal.init();
    }

    public initArchive(chosenProfilesIds: () => string[],
                       currentTabel: DataTables.Api) {
        const $this = this;
        const modalSelector = "#archive-profile-modal";

        $this.archivePersonsModal = new Modal(
            ".archive-persons",
            modalSelector,
            (triggerElement : JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("PersonalProfile_ArchiveModal_Title"), currentTabel, triggerElement),
            () => null,
            () => {
                this.garudaApi.archiveProfile(chosenProfilesIds()).then(() => {
                    window.location.reload();
                });
            }
        );

        $this.archivePersonsModal.init();
    }

    public initWithdrawConsent(chosenProfilesIds: () => string[],
                               currentTabel: DataTables.Api) {
        const $this = this;
        const modalSelector = "#withdraw-consent-profile-modal";

        $this.withdrawConsentPersonsModal = new Modal(
            ".withdraw-consent-persons",
            modalSelector,
            (triggerElement : JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("PersonalProfile_WithdrawConsentModal_Title"), currentTabel, triggerElement),
            () => null,
            () => {
                this.garudaApi.withdrawConsentForProfile(chosenProfilesIds()).then(() => {
                    window.location.reload();
                });
            }
        );

        $this.withdrawConsentPersonsModal.init();
    }

    public initEditProfile(respondentListModule : RespondentListModule) {
        if(respondentListModule.subtool === SubToolEnum.FocusGroup) return;

        const saveProfileCallback = (modalSelector: JQuery<HTMLElement>) => {
            modalSelector.modal("hide");
            respondentListModule.hideList();

            NotificationModule.showSuccessSmall(Localization.getText("Respondent_Editted"));

            this.garudaApi.getRespondentListPartial(respondentListModule.tool, respondentListModule.subtool, respondentListModule.limit)
                .then((html: string) => {
                    $(respondentListModule.respondentListContainer).html(html);
                    respondentListModule.initTable();
                    respondentListModule.showList();
                });
        }

        this.editRespondentAction.initEditProfileModal(respondentListModule.tool, respondentListModule.subtool, saveProfileCallback);
    }

    public initEditTags(respondentList : RespondentListModule,
                        currentTable : DataTables.Api) {
        const $this = this;
        const modalSelector = "#modal-edit-respondent-tags";
        const modalApplySelector = "#modal-btn-apply";

        this.editTagsModal = new Modal(
            ".edit-respondent-tags",
            modalSelector,
            () => $this.garudaApi.getEditRespondentTagsModal(),
            () => {
                initializeEditTagsReactApp(respondentList,
                    $this.getMultiRespondentEditModel(currentTable),
                    modalSelector,
                    modalApplySelector,
                    respondentList.subtool);
            });

        this.editTagsModal.init();
    }

    public initGraphIQ(respondentList: RespondentListModule) {
        const modal = new Modal(
            ".open-garudagraph-iq-modal-button",
            "#garuda-logik-iq-modal",
            (triggerElement : JQuery<HTMLElement>) => this.garudaApi.GetGarudaGraphModal(),
            (triggerElement : JQuery<HTMLElement>) => {
                const profileId = triggerElement[0].dataset["profileId"];
                const graphicModule = new GrafModule({
                    profileId: profileId,
                    typeIds: ["1","2","3","4"],
                    grafContainerSelector: "#IQ-result-graph",
                    graphicType: GraphicTypeEnum.IQTraits,
                });
                graphicModule.init();

                const graphicModuleTotals = new GrafModule({
                    profileId: profileId,
                    typeIds: ["2"],
                    grafContainerSelector: "#IQ-result-total",
                    graphicType: GraphicTypeEnum.IQTotals,
                });
                graphicModuleTotals.init()
            },
        );
    modal.init();
    }

    public initCreateGroupProfile(respondentList: RespondentListModule, currentTable: DataTables.Api) {
        const $this = this;
        const modalSelector = "#new-focusgroup-profile";
        const modalApplySelector = "#modal-btn-apply";
        if (respondentList.subtool === SubToolEnum.FocusGroup) return;

        const saveProfileCallback = () => window.location.href = focusGroupProfileListUrl;

        this.createStaticGroupProfileModal = new Modal(
            ".create-static-group-profile",
            modalSelector,
            () => $this.garudaApi.getGroupProfileEditModal(),
            () => {
                initializeCreateStaticGroupProfileReactApp(
                    $this.getSelectedProfileIds(currentTable, "group-profile"),
                    $(modalSelector),
                    modalApplySelector,
                    saveProfileCallback);
            });

        this.createStaticGroupProfileModal.init();
    }

    public initReportGeneration(currentTable: DataTables.Api) {
        const $this = this;

        $(".generate-single-respondent-report").off("click").on("click", async (event) => {
            const eventTarget = $(event.currentTarget);
            const profileId = $(eventTarget).data("profileId");
            const respondentName = $(eventTarget).closest("tr").data("respondent-name");
            const subTool = $(eventTarget).data("subtool-id-number");
            await $this.reportModule.initialize([profileId], PluralityEnum.Single, true, respondentName, subTool);
        });

        $(".generate-multiple-respondent-reports").off("click").on("click", async (event) => {
            const profileIds = this.getSelectedProfileIds(currentTable, "respondent-reports");
            await $this.reportModule.initialize(profileIds, PluralityEnum.Multiple, true);
        });

        $(".generate-group-reports").off("click").on("click", async (event) => {
            const profileIds = this.getSelectedProfileIds(currentTable, "respondent-reports");
            await $this.reportModule.initialize(profileIds, PluralityEnum.Group, true);
        });
    }

    public initSharing(respondentList: RespondentListModule, currentTable: DataTables.Api) {
        $(".single-profile-sharing").off("click").on("click",  async (e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => {
            const profileId = e.currentTarget.dataset.profileId;
            await respondentList.shareSingleProfile(profileId);
        });

        $(".multi-profile-sharing").off("click").on("click",  async () => {
            const profileIds = this.getSelectedProfileIds(currentTable, "can-share");
            await respondentList.shareMultiProfile(profileIds);
        });
    }

    public initTransferOwnership(respondentList: RespondentListModule, currentTable: DataTables.Api) {
        $(".transfer-single-respondent-ownership").off("click").on("click", async (e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => {
            const respondentId = e.currentTarget.dataset.respondentId;
            const profileId = e.currentTarget.dataset.profileId;
            await respondentList.transferOwnership(respondentList, [respondentId], [profileId], "#modal-transfer-respondent-ownership");
        });

        $(".transfer-respondent-ownership").off("click").on("click", async () => {
            const respondentIds = this.getMultiRespondents(currentTable);
            const profileIds = this.getProfileIds(currentTable);
            await respondentList.transferOwnership(respondentList, respondentIds, profileIds, "#modal-transfer-multiple-respondent-ownership");
        });
    }

    public initEventlogModal(respondentList: RespondentListModule) {
        $(".open-eventlog-modal-button").off("click").on("click", async (e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => {
            const isProfile = e.currentTarget.dataset.isProfile === "True";
            const id = isProfile ? e.currentTarget.dataset.profileId : e.currentTarget.dataset.respondentId;
            await respondentList.openEventlog(id, isProfile);
        });
    }

    public initRespondentMerging(respondentList: RespondentListModule, currentTable: DataTables.Api) {
        $(".merge-respondents").off("click").on("click", async () => {
            const respondentIds = this.getSelectedRespondentIds(currentTable, "can-merge");
            await respondentList.mergeRespondents(respondentIds);
        });
    }

    private getSelectedProfileIds(currentTable: DataTables.Api, rowFilterDataAttribute: string):string[] {
        return this.getSelectedRowDataValues("profileId", currentTable, rowFilterDataAttribute);
    }

    private getSelectedRespondentIds(currentTable: DataTables.Api, rowFilterDataAttribute: string):string[] {
        return this.getSelectedRowDataValues("respondentId", currentTable, rowFilterDataAttribute);
    }

    private getSelectedRowDataValues(dataProperty: string, currentTable: DataTables.Api, rowFilterDataAttribute: string):string[] {
        const dataValues: string[] = [];
        const selectedRows = currentTable.rows(".selected").nodes();
        selectedRows.filter(row => $(row).data(rowFilterDataAttribute) === "True").map(element => {
            dataValues.push($(element).data(dataProperty));
        });
        return dataValues;
    }

    private setNamesInProfileActionModal(modalSelector: string,
                                         modalTitlePrefix: string,
                                         currentTable : DataTables.Api,
                                         htmlElement?: JQuery<HTMLElement>) : Promise<string> {
        const respondentNames: string[] = [];
        const profileIds: string[] = [];

        if (htmlElement !== undefined && htmlElement.data("plurality") === PluralityEnum[PluralityEnum.Single].toLowerCase()) {
            const tr = htmlElement.closest("tr");
            respondentNames.push($(tr).data("respondent-name"));
            profileIds.push($(tr).data("profile-id"));
        } else {
            const selectedRows = currentTable.rows(".selected").nodes();
            selectedRows.filter(row => $(row).data("respondent-owner") === "True").map(element => {
                respondentNames.push($(element).data("respondent-name"));
                profileIds.push($(element).data("profile-id"));
            });
        }

        return this.setModalData(modalSelector, modalTitlePrefix, respondentNames, profileIds);
    }

    public setModalData(modalSelector: string,
                        modalTitlePrefix: string,
                        respondentNames: string[],
                        profileIds: string[]): Promise<string> {
        const profileActionModal = $(modalSelector);

        const profileActionModalTitle = profileActionModal.find(".modal-title");
        profileActionModalTitle.empty();
        profileActionModalTitle.append(`${modalTitlePrefix} (${respondentNames.length})`);

        const profileActionModalContent = profileActionModal.find(".modal-body");
        profileActionModalContent.empty();

        respondentNames.forEach(name => {
            profileActionModalContent.append(`<div>${name}</div>`);
        });

        profileIds.forEach(personprofileId => {
            profileActionModalContent.append(`<input type="hidden" class="profile-action-id" value="${personprofileId}" />`);
        });

        return new Promise<string>((resolve) => {
            resolve(profileActionModalContent.html());
        });
    }

    private getMultiRespondentEditModel(currentTable : DataTables.Api) : MultiRespondentEditModel {
        const multiRespondentEditModel = new MultiRespondentEditModel();
        let expirationDateIsSet = false;
        let respondentTypeIsSet = false;

        const selectedRows = currentTable.rows(".selected").nodes();
        selectedRows.map(function (element) {

            const profileTags = $(element).data("profile-tags") as string;
            const profileId = $(element).data("profile-id");
            const profileTagsModel = new TagsModel();
            profileTagsModel.contextId = profileId;
            profileTagsModel.tags = profileTags?.length > 0 ? profileTags.split("$,$") : [];
            multiRespondentEditModel.profileTags.push(profileTagsModel);

            const respondentType = $(element).data("respondent-type") as number;
            multiRespondentEditModel.respondentType = respondentTypeIsSet ?
                multiRespondentEditModel.respondentType === respondentType ? respondentType : null : respondentType;
            respondentTypeIsSet = true;

            const expirationDateTime = $(element).data("respondent-expiration-date") as string;
            multiRespondentEditModel.respondentExpirationDate = expirationDateIsSet ?
                multiRespondentEditModel.respondentExpirationDate === expirationDateTime ? expirationDateTime : null : expirationDateTime;
            expirationDateIsSet = true;

            const respondentTags = $(element).data("respondent-tags") as string;
            const respondentId = $(element).data("respondent-id");
            const respondentTagModel = new TagsModel();
            respondentTagModel.contextId = respondentId;
            respondentTagModel.tags = respondentTags?.length > 0 ? respondentTags.split("$,$") : [];
            multiRespondentEditModel.respondentTags.push(respondentTagModel);

            const isOwner = $(element).data("respondent-owner") as string;
            if (isOwner === "False"){
                multiRespondentEditModel.allRespondentsIsOwned = false;
            }
        });

        return multiRespondentEditModel;
    }

    private getMultiRespondents(currentTable: DataTables.Api): string[] {

        const selectedRows = currentTable.rows(".selected").nodes();
        var respondents: string[] = [];

        selectedRows.map(function (element) {
            const respondentId = $(element).data("respondent-id");
            respondents.push(respondentId);
        });

        return respondents;
    }
    private getProfileIds(currentTable: DataTables.Api) {
        const selectedRows = currentTable.rows(".selected").nodes();
        var profileIds: string[] = [];
        selectedRows.map(function (element) {
            const profileId = $(element).data("profile-id");
            profileIds.push(profileId)
        });

        return profileIds;
    }
}
