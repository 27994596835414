import {GarudaApi} from "../../infrastructure/garudaApi";
import {MimeTypeEnum} from "../../models/enums/mimeTypeEnum";

export class FileDownloadModule {
    private garudaApi: GarudaApi;

    constructor() {
        this.garudaApi = new GarudaApi();
    }

    public async OpenReport(fileUrl: string, mimeType: MimeTypeEnum) {
        if (mimeType === MimeTypeEnum.Pdf) {
            window.open(fileUrl);
        } else {
            window.location.assign(fileUrl);
        }
    }
}
