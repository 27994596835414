import { Localization } from "../modules/localization-module";

export class RespondentDistributionModel {
    respondentId : string;
    profileId: string;
    email: string;
    emailBody: string;

    phoneNumber: string;
    countryCode: string;
    smsBody: string;
    sendSms: boolean;
    sendCopy: boolean;

    subject: string

    public isValid(): string {
        if (!this.email || this.email.length < 1)
            return Localization.getText("Global_Fill_Fields");

        const emailValid = this.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
        if (emailValid === null)
            return Localization.getText("Global_Email_Invalid");

        if (!this.subject || !this.subject?.trim())
            return Localization.getText("Global_Fill_Fields");

        if (!this.emailBody || !this.emailBody?.trim())
            return Localization.getText("Global_Fill_Fields");

        if (this.sendSms &&
            (!this.countryCode?.trim() || !this.phoneNumber?.trim() || !this.smsBody?.trim()))
                return Localization.getText("Global_Fill_Fields");

        return "";
    }


    public distributionMapper_LocalStorageMapper(src: RespondentDistributionModel, dist: RespondentDistributionModel): RespondentDistributionModel {
        dist.sendCopy = src.sendCopy;
        dist.sendSms = src.sendSms;

        return dist;
    }
}
