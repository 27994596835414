export enum ProfilePurposeEnum {
    Other = "0",
    Demo = "1",
    Training = "2",
    Recruiting = "3",
    Onboarding = "4",
    DevelopmentIndividual = "5",
    DevelopmentTeam = "6",
    Outplacement = "7",
}
