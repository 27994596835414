import * as React from "react";
import { ToolCrudModel} from "../../models/profileCrud/ToolCrudModel";
import { GarudaApi } from "../../infrastructure/garudaApi";
import { RespondentModel } from "../../models/profileCrud/RespondentModel";
import { ProfileSimpleEditComponent } from "./profile-crud-components/ProfileSimpleEditComponent";
import { ProfileCreateComponent } from "./profile-crud-components/ProfileCreateComponent";

interface IProfilProps {
    selectTool?: (toolId: string) => void;
    selectSubTool: (toolId: string) => void;
    selectLanguage: (purpose: string) => void;
    selectSetting: (settingKey: string, value: string) => void;
    selectSpecialSettings: (settingKey: string, value: string) => void;
    selectProfileAssociation: (settingKey: string, profileId: string, associatedName: string) => void;
    selectGarudaLogicSubtool: (subToolId?: string) => void;
    getSetting: (settingKey: string) => any;
    selectProfilesharings: () => void;
    setValue: (key: string, val: string | string[]) => void;
    toolData: ToolCrudModel;
    respondent: RespondentModel;
    isEdit: boolean;
}

interface IProfileState {
    availableUserConnections: any[];
}

export class RespondentAnalysisComponent extends React.Component<IProfilProps, IProfileState> {

    private garudaApi: GarudaApi;

    constructor(props: IProfilProps) {
        super(props);

        this.garudaApi = new GarudaApi();

        this.state = {
            availableUserConnections: null
        }
    }

    public render() {
        return (
            <>
                <div className="row ">
                    <div className="col-sm-12">
                        {!this.props.isEdit &&
                        <ProfileCreateComponent
                            selectTool={this.props.selectTool}
                            selectSubTool={this.props.selectSubTool}
                            selectLanguage={this.props.selectLanguage}
                            selectSetting={this.props.selectSetting}
                            getSetting={this.props.getSetting}
                            selectSpecialSettings={this.props.selectSpecialSettings}
                            selectProfileAssociation={this.props.selectProfileAssociation}
                            selectGarudaLogicSubtool={this.props.selectGarudaLogicSubtool}
                            selectProfilesharings={this.props.selectProfilesharings}
                            setValue={this.props.setValue}
                            toolData={this.props.toolData}
                            respondent={this.props.respondent} />
                        }
                        {this.props.isEdit &&
                        <ProfileSimpleEditComponent
                            selectLanguage={this.props.selectLanguage}
                            selectSetting={this.props.selectSetting}
                            getSetting={this.props.getSetting}
                            selectSpecialSettings={this.props.selectSpecialSettings}
                            selectProfilesharings={this.props.selectProfilesharings}
                            toolData={this.props.toolData}
                            respondent={this.props.respondent}
                            setValue={this.props.setValue}/>
                        }
                    </div>
                </div>
            </>);
    }
}
