import React = require("react");

export interface ILabelProps {
    text: string;
    htmlFor: string;
    isRequired?: boolean;
}

export const Label = (props: ILabelProps) => (<label htmlFor={props.htmlFor}>
    {props.text}{props.isRequired ? <span className={"required-field"}>*</span> : <></>}
</label>);
