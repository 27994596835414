import { PopoverModule } from "ditmer-embla";
import * as React from "react";
import { useEffect, useRef } from "react";

export interface IPopoverProps {
    content: string;
    classes?: string;
}

export const Popover = (props: React.PropsWithChildren<IPopoverProps>) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        initPopover(popoverRef);
    }, [popoverRef, props.content])

    return (
        <div ref={popoverRef} className={`d-inline-block tooltip-wrapper ${props.classes}`} tabIndex={-1} data-trigger="hover" data-toggle="popover" data-content={props.content}>
            {props.children}
        </div>
    )
}

export default Popover;

const initPopover = (popoverRef: React.RefObject<HTMLDivElement>) => {
    if(popoverRef.current === null) return;

    const popoverToBeInitialized = $(popoverRef.current);
    new PopoverModule(popoverToBeInitialized, { showHtml: true, sanitizeContent: false, trigger: "hover", placement: "bottom" });
}