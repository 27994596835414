import * as React from "react";
import {Localization} from "../../modules/localization-module";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {RespondentComponent} from "./RespondentComponent";
import {WizardComponent} from "./WizardComponent";
import {RespondentAnalysisComponent} from "./RespondentAnalysisComponent";
import {RespondentModel} from "../../models/profileCrud/RespondentModel";
import {RespondentDistributionComponent} from "./RespondentDistributionComponent";
import {wizardStepAnalysis, wizardStepDistribution, wizardStepRespondent} from "../../models/constants/wizardConstants";
import {RespondentDistributionModel} from "../../models/RespondentDistributionModel";
import {SettingsValueModel} from "../../models/profileCrud/SettingsValueModel";
import {SpinnerComponent} from "../ui-components/SpinnerComponent";
import {ToolCrudModel} from "../../models/profileCrud/ToolCrudModel";
import {CreateRepondentReponseModel} from "../../models/profileCrud/CreateRepondentReponseModel";
import {TagEnum} from "../../models/enums/TagEnum";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {RespondentExistingModel} from "../../models/profileCrud/RespondentExistingModel";
import {SelectedTabEnum} from "../../models/enums/respondentSelectedTabEnum";
import {RespondentSplit} from "./RespondentSplit";
import {RespondentProfilesSplitViewModel} from "../../models/respondent/RespondentProfilesSplitViewModel";
import {EmblaIcon} from "../ui-components/EmblaIcon";
import {CustomTooltip} from "../ui-components/CustomTooltip";
import useLatestRespondentCreatedHook from "../reactHooks/useLocalstorageHook";
import {
    settingConstant_SelfEvaluation,
    settingsConstants_sms_notify_user,
    settingsConstantsAnalysisPurpose, settingsConstantsProfileAssociation, subtoolMirror, subtoolMirrorFollowup
} from "../../infrastructure/constants";

interface RespondentProps {
    isEdit: boolean;
    respondentId?: string;
    profileId?: string;
    saveCallbackButtonSelector?: string;
    saveCallback?: () => void;
    saveCallbackValidation?: (error: string) => void;
    showAnalysisComponent?: boolean;
    redirectUrl: string
    initCustomTooltips: () => void;
    userId?: string
    subtool?: SubToolEnum;
}

interface RespondentState {
    isLoading: boolean;
    isSelectInitialized: boolean;
    data: ToolCrudModel;
    existingRespondents: RespondentExistingModel[];
    respondent: RespondentModel;
    distribution: RespondentDistributionModel;
    respondentSplitViewModel?: RespondentProfilesSplitViewModel;
    createFromNewOrExistingRespondent: SelectedTabEnum;
    startAtStepTwo: boolean;
}

export class RespondentApp extends React.Component<RespondentProps, RespondentState> {
    private readonly selectedToolSelector = $("#SelectedToolId");
    private readonly selectedSubtoolSelector = $("#SelectedSubtoolId");
    private readonly selectedLanguageSelector = $("#SelectedLanguage");
    private readonly garudaApi: GarudaApi;
    private readonly storage = useLatestRespondentCreatedHook<RespondentModel>();
    private readonly storageUserId = useLatestRespondentCreatedHook<string>();
    private readonly RESPONDANT_STORAGE_KEY ="createRespondantStorageKey";
    private readonly RESPONDANT_CREATOR_KEY ="respCreatorKey";

    private settingsSelectedNotToOverrideOnPreeLoad = new Map<string, string>()

    constructor(props: RespondentProps) {
        super(props);

        this.garudaApi = new GarudaApi();
        this.state = {
            isLoading: true,
            data: null,
            isSelectInitialized: false,
            respondent: new RespondentModel(),
            distribution: new RespondentDistributionModel(),
            existingRespondents: null,
            respondentSplitViewModel: null,
            createFromNewOrExistingRespondent: SelectedTabEnum.newRespondent,
            startAtStepTwo: false,
        };

        this.settingsSelectedNotToOverrideOnPreeLoad.set(settingsConstantsAnalysisPurpose, null);
    }

    private selectTool(toolId: string): void {
        const respondent = this.state.respondent;
        respondent.selectedToolId = toolId;
        respondent.selectedSubtoolId = null;
        respondent.garudaLogicSubToolId = null;
        this.reloadSettings(respondent);

        this.setState({
            respondent: respondent
        });
        this.storage.removeStorage(this.RESPONDANT_STORAGE_KEY);

        this.selectedToolSelector.val(toolId);
        this.selectedSubtoolSelector.val(null);
        this.selectedLanguageSelector.val(null);
    }

    private selectSubTool(subtoolId: string) {
        const respondent = this.state.respondent;
        respondent.selectedSubtoolId = subtoolId;
        if(respondent.selectedSubtoolId !== subtoolMirror && respondent.selectedSubtoolId !== subtoolMirrorFollowup) {
            var index = respondent.settingValues.findIndex(x=>x.key === settingsConstantsProfileAssociation);
            respondent.settingValues.splice(index,1);
        }
        respondent.garudaLogicSubToolId = null;

        this.setState({
            respondent: respondent
        });
        this.storage.removeStorage(this.RESPONDANT_STORAGE_KEY);
        this.selectedSubtoolSelector.val(subtoolId);
        this.selectedLanguageSelector.val(null);
    }

    private selectLanguage(languageKey: string) {
        const respondent = this.state.respondent;
        respondent.selectedLanguage = languageKey;

        this.setState({
            respondent: respondent
        });

        this.selectedLanguageSelector.val(languageKey);
    }

    private settingSelected(settingKey: string, value: string) {
        const respondent = this.state.respondent;
        respondent.settingValues.find(sv => sv.key === settingKey).setValue(value);
        this.setState({
            respondent: respondent
        });
    }

    private getSettingValue(settingKey: string) {
        const respondent = this.state.respondent;
        if (!respondent || !respondent.settingValues)
            return;

        const settingsValueModel = this.state.respondent.settingValues.find(k => k.key === settingKey);

        if (settingsValueModel === undefined)
            return;

        return settingsValueModel.value;
    }

    private setProfileAssociation(key: string, profileId: string, associatedName: string) {
        this.state.respondent.associatedProfileName = associatedName;
        this.setSpecialSetting(key, profileId);
    }

    private setGarudaLogicSubTool(subToolId?: string) {
        const respondent = this.state.respondent;
        respondent.garudaLogicSubToolId = subToolId?.length > 1 ? subToolId : null;
        this.setState({
            respondent: respondent
        });
    }

    private setSpecialSetting(key: string, value: string) {

        if (value === null)
            return;

        const specialSettings = this.state.respondent.specialSettingValues;

        const entry = specialSettings.find(k => k.key === key) as SettingsValueModel;

        if (entry === undefined) {
            const setting = new SettingsValueModel(key, value, false);
            setting.setValue(value);
            specialSettings.push(setting);
        } else {
            entry.setValue(value);
        }

        const respondent = this.state.respondent;
        respondent.specialSettingValues = specialSettings;
        this.setState({
            respondent: respondent
        });
    }

    private setRespondentValue(field: string, value: string | string[]) {
        const respondent = this.state.respondent;
        // @ts-ignore
        respondent[field] = value;
        this.setState({
            respondent: respondent
        });
    }

    private setRespondentId(value: string) {
        const respondent = this.state.respondent;
        respondent.id = value;

        this.setState({
            respondent: respondent
        });
    }

    private setRespondentType(value: number) {
        const respondent = this.state.respondent;
        respondent.respondentType = value;
        this.setState({
            respondent: respondent
        });
    }

    private setDistributionValue(field: string, value: string | string[] | boolean) {
        const distribution = this.state.distribution;
        // @ts-ignore
        distribution[field] = value;
        this.setState({
            distribution: distribution
        });
    }

    private setProfileSharings() {
        const respondent = this.state.respondent;
        respondent.profileSharing = RespondentApp.buildUserConnectionsList();
    }

    private static buildUserConnectionsList() {
        const select2Data = $("#available-user-connections").select2("data");

        return select2Data.map((option) => {
            return option.id;
        });
    }

    private async saveRespondent() {
        const respondent = this.state.respondent;

        // Remove Profile Association Setting
        respondent.settingValues = respondent.settingValues.filter(vs => vs.key != settingsConstantsProfileAssociation);

        if (!this.props.isEdit) {
            this.storage.setValue(this.RESPONDANT_STORAGE_KEY, respondent);
            this.storageUserId.setValue(this.RESPONDANT_CREATOR_KEY, this.props.userId);
            const promise = this.garudaApi.saveRespondent(respondent);

            promise.then((createRepondentReponseModel: CreateRepondentReponseModel) => {
                this.setRespondentValue("profileId", createRepondentReponseModel.profileId);
                this.setDistributionValue("respondentId", createRepondentReponseModel.respondentId);
                this.setRespondentValue("additionalGarudaLogicProfileId", createRepondentReponseModel.additionalGarudaLogicProfileId);
            });

            return promise;
        } else {
            await this.garudaApi.updateRespondent(respondent);
        }
    }

    private async distributeRespondent() {
        const distribution: RespondentDistributionModel = this.state.distribution;
        distribution.profileId = this.state.respondent.profileId;
        await this.garudaApi.distributeRespondent(distribution).then(() => {
            if (this.props.redirectUrl) {
                window.location.assign(this.props.redirectUrl)
                return;
            }

            window.location.assign("/");
        });
    }

    private clearProfile(respondent: RespondentModel): void {
        respondent.tags = [];
        respondent.questionnaireTags = [];
        respondent.profilstatus = undefined;
        respondent.respondentType = undefined;
        respondent.selectedToolId = undefined;
        respondent.selectedSubtoolId = undefined;
        respondent.selectedLanguage = undefined;
        respondent.settingValues = [];
        respondent.specialSettingValues = [];
        respondent.profileSharing = [];
        respondent.expiryDate = undefined;
        respondent.profileId = undefined;
    }

    private async updateRespondentInfo() {
        let respondent: RespondentModel = this.state.respondent;
        let distribution: RespondentDistributionModel = this.state.distribution;

        if (this.props.isEdit) {
            respondent = Object.assign(new RespondentModel(), await this.garudaApi.getRespondent(this.props.respondentId, this.props.profileId));
            respondent.plainToClass();

            const $this = this;
            $(this.props.saveCallbackButtonSelector).off("click").on("click", async function () {
                await $this.saveRespondent();
                $this.props.saveCallback();
            });
        } else {
            if (this.props.respondentId && this.props.profileId) {
                respondent = Object.assign(new RespondentModel(), await this.garudaApi.getRespondent(this.props.respondentId, this.props.profileId));
                respondent.plainToClass();
                this.clearProfile(respondent);
                this.setDistributionValue("email", respondent.email);
                this.setState({startAtStepTwo: true});
            }
            respondent.tags = await this.garudaApi.getCompanyTags(TagEnum.RespondentTag)
            respondent.questionnaireTags = await this.garudaApi.getCompanyTags(TagEnum.ProfileTag);
            const userInformation = await this.garudaApi.getRespondantsUserInformation();
            respondent.countryCode = userInformation.countryCode;
            respondent.selectedLanguage = userInformation.language;
        }

        const toolData = await this.garudaApi.getTools(respondent.respondentType);
        const existingRespondentData = await this.garudaApi.getExistingRespondents();
        const toolCrudModel = new ToolCrudModel();
        toolCrudModel.tools = toolData.tools;
        toolCrudModel.commonSettings = toolData.commonSettings;
        toolCrudModel.respondentTypeEnumModels = toolData.respondentTypeEnumModels;
        respondent.setSettingsDefaultValues(toolCrudModel);
        respondent.plainToClass();

        let respondentProfilesSplitViewModel: RespondentProfilesSplitViewModel;
        if(this.props.isEdit){
            respondentProfilesSplitViewModel = await this.garudaApi.getRespondentProfilesToSplit(respondent.id);
        }

        if(!this.props.isEdit) {
            const smsNotify = this.state.respondent.settingValues.find(x=>x.key === settingsConstants_sms_notify_user);
            if (smsNotify) {
                this.settingsSelectedNotToOverrideOnPreeLoad.set(settingsConstants_sms_notify_user, smsNotify.value);
            }

            const selfEvaluation = this.state.respondent.settingValues.find(x=>x.key === settingConstant_SelfEvaluation);
            if (selfEvaluation) {
                this.settingsSelectedNotToOverrideOnPreeLoad.set(settingConstant_SelfEvaluation, selfEvaluation.value);
            }

            respondent.setSettingValuesToNulstillingOnMapping(this.settingsSelectedNotToOverrideOnPreeLoad);
            const userCreatedId = this.storageUserId.getValue(this.RESPONDANT_CREATOR_KEY, null, (string, distString )=> {
                return string
            })

            if(userCreatedId == this.props.userId) {
                const storageRespondent = this.storage.getValue(this.RESPONDANT_STORAGE_KEY, respondent, respondent.mapper_LocalStorage);
                if (storageRespondent) {
                    respondent = storageRespondent;
                }
            }
        }

        this.setState({
            distribution: distribution,
            respondent: respondent,
            data: toolCrudModel,
            isLoading: false,
            existingRespondents: existingRespondentData,
            respondentSplitViewModel: respondentProfilesSplitViewModel
        });
    }

    private reloadSettings(respondent: RespondentModel) {
        respondent.setSettingsDefaultValues(this.state.data);
        respondent.plainToClass();
    }

    public async componentDidMount() {
        this.updateRespondentInfo();
    }

    public componentDidUpdate() {
        if (this.props.isEdit) {
            this.props.saveCallbackValidation(this.state.respondent.respondentNewValid());
        }

        if (this.state.isSelectInitialized === false && this.state.isLoading === false) {

            this.setState({
                isSelectInitialized: true
            });
        }

        this.props.initCustomTooltips();
    }

    private setSelectedTab(selected: SelectedTabEnum) {
        this.setState({
            createFromNewOrExistingRespondent: selected
        });

        if(selected == SelectedTabEnum.newRespondent) {
            this.updateRespondentInfo();
        }
    }

    private renderWizardContent(step: number) {
        if (step === wizardStepRespondent) {
            return (<RespondentComponent setValue={(k, v) => this.setRespondentValue(k, v)}
                                         setDistributionValues={(k, v) => this.setDistributionValue(k, v)}
                                         setRespondentType={(value) => this.setRespondentType(value)}
                                         respondent={this.state.respondent}
                                         existingRespondents={this.state.existingRespondents}
                                         setSelectedTab={(value) => this.setSelectedTab(value)}
                                         setRespondentId={(value) => this.setRespondentId(value)}
            />);
        } else if (step === wizardStepAnalysis) {
            return (<RespondentAnalysisComponent
                respondent={this.state.respondent}
                toolData={this.state.data}
                selectTool={(value) => this.selectTool(value)}
                selectSubTool={(value) => this.selectSubTool(value)}
                selectLanguage={(value) => this.selectLanguage(value)}
                selectSetting={(key, value) => this.settingSelected(key, value)}
                getSetting={(key: string) => this.getSettingValue(key)}
                selectSpecialSettings={(key: string, value: string) => this.setSpecialSetting(key, value)}
                selectProfileAssociation={(key: string, profileId: string, associatedName: string) => this.setProfileAssociation(key, profileId, associatedName)}
                selectGarudaLogicSubtool={(subToolId?: string) => this.setGarudaLogicSubTool(subToolId)}
                selectProfilesharings={() => this.setProfileSharings()}
                setValue={(k, v) => this.setRespondentValue(k, v)}
                isEdit={false}
            />);
        } else if (step === wizardStepDistribution) {
            return (<RespondentDistributionComponent
                respondent={this.state.respondent}
                distributionModel={this.state.distribution}
                toolId={this.state.respondent.selectedToolId}
                subtoolId={this.state.respondent.selectedSubtoolId}
                onSendCopy={(v) => this.setDistributionValue("sendCopy", v)}
                onEmailBodyChanged={(v) => this.setDistributionValue("emailBody", v)}
                onPhoneNumberChanged={(v) => this.setDistributionValue("phoneNumber", v)}
                onCountryCodeChanged={(v) => this.setDistributionValue("countryCode", v)}
                onSmsBodyChanged={(v) => this.setDistributionValue("smsBody", v)}
                onSendSmsChanged={(v) => this.setDistributionValue("sendSms", v)}
                onEmailChanged={(v) => this.setDistributionValue("email", v)}
                onSubjectChanged={(v) => this.setDistributionValue("subject", v)}
            />);
        }
    }

    public validateComponent(element: JSX.Element): string {
        if (element.type === RespondentComponent) {

            if (this.state.createFromNewOrExistingRespondent === SelectedTabEnum.newRespondent) {
                return this.state.respondent.respondentNewValid();
            }

            if (this.state.createFromNewOrExistingRespondent === SelectedTabEnum.existingRespondent) {
                return this.state.respondent.respondentExistingValid();
            }
        }

        if (element.type === RespondentAnalysisComponent) {
            const questionnaireSettings = this.state.data.AllQuestionarySettings().map(qs => qs.key);
            return this.state.respondent.profileValid(questionnaireSettings);
        }

        if (element.type === RespondentDistributionComponent) {
            return this.state.distribution.isValid();
        }
    }

    public render() {
        if (this.state.isLoading) {
            return (<SpinnerComponent/>);
        }

        return (
            <div className="respondent-crud">

                {!this.props.isEdit &&
                <WizardComponent content={(step) => this.renderWizardContent(step)}
                                 headers={[
                                     Localization.getText("ERESPONDENT"),
                                     Localization.getText("GENERAL_QUESTIONNAIRE"),
                                     Localization.getText("GENERAL_DISTRIBUTE")
                                 ]}
                                 contentValid={(element) => this.validateComponent(element)}
                                 saveRespondentClick={() => this.saveRespondent()}
                                 distributeRespondentClick={() => this.distributeRespondent()}
                                 currentStep={this.state.startAtStepTwo?2:1}
                />
                }

                {this.props.isEdit &&
                <div className="row">
                  <div className="col-md-12">
                    <div className={"margin-bottom-s"}>
                      <h4>
                          {Localization.getText("ERESPONDENT")}
                      </h4>
                    </div>
                    <div>
                      <RespondentComponent
                        setDistributionValues={(k, v) => this.setDistributionValue(k, v)}
                        setValue={(k, v) => this.setRespondentValue(k, v)}
                        setRespondentType={(value) => this.setRespondentType(value)}
                        respondentTypeEnumModels={this.state.data.respondentTypeEnumModels}
                        respondent={this.state.respondent}
                        subtool={this.props.subtool}
                        existingRespondents={this.state.existingRespondents}
                        setSelectedTab={(value) => this.setSelectedTab(value)}
                      />
                    </div>
                  </div>

                    {this.props.showAnalysisComponent &&
                    <div>
                      <div className="col-md-12">
                        <hr/>
                      </div>

                      <div className="col-md-12">
                        <div className="margin-bottom-s">
                          <h4>
                              {Localization.getText("Global_Questionary")}
                          </h4>

                            {this.state.respondent.isLocked() &&
                            <div
                              className="subtle margin-bottom-m"> {Localization.getText("PersonalProfile_Edit_Locked")}</div>
                            }
                        </div>

                        <RespondentAnalysisComponent
                            respondent={this.state.respondent}
                            toolData={this.state.data}
                            selectLanguage={(value) => this.selectLanguage(value)}
                            selectProfilesharings={() => this.setProfileSharings()}
                            selectSubTool={null}
                            selectSetting={(key, value) => this.settingSelected(key, value)}
                            getSetting={(settingKey: string) => this.getSettingValue(settingKey)}
                            selectSpecialSettings={(key: string, value: string) => this.setSpecialSetting(key, value)}
                            selectProfileAssociation={(key: string, profileId: string, associatedName: string) => this.setProfileAssociation(key, profileId, associatedName)}
                            selectGarudaLogicSubtool={(subToolId?: string) => this.setGarudaLogicSubTool(subToolId)}
                            setValue={(k, v) => this.setRespondentValue(k, v)}
                            isEdit={true}
                          />
                      </div>
                    </div>
                    }

                    {!this.props.showAnalysisComponent &&
                    this.state.respondentSplitViewModel.profileIdentifierViewModels.length > 0 &&
                    <div className="col-md-12 mt-4">
                      <h4 className="margin-bottom-m">
                          {Localization.getText("General_Questionnaires")}
                        <span className="header-info-icon-wrapper margin-left-xs">
                          <EmblaIcon iconName="info"
                                     onMouseMove={(e) => window.showTooltip(e, '', '', Localization.getText("Respondent_SplitProfile_Info"), null)}
                                     onMouseOut={window.hideTooltip} />
                        </span>
                      </h4>
                      <div>
                        <RespondentSplit
                          respondent={this.state.respondent}
                          respondentSplitViewModel={this.state.respondentSplitViewModel}
                          saveCallback={this.props.saveCallback} />
                      </div>
                    </div>
                    }
                </div>
                }

                <CustomTooltip/>
            </div>
        );
    }
}
