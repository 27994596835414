import { StateStorage } from "../../infrastructure/stateStorage";
import { currentAttachedProfilesStorageKey } from "../../infrastructure/constants";
import { SelectedProfileModel } from "../../models/SelectedProfileModel";
import {SubToolEnum} from "../../models/enums/subToolEnum";

interface IComparedProfilesProps {
    comparedProfilesContainerSelector: string;
    comparedProfilesChangeCallback: () => void;
    primaryProfileId: string;
    subtoolsToExclude : SubToolEnum[];
}

export class ComparedProfilesModule {
    private readonly comparedProfilesProps: IComparedProfilesProps;
    private readonly stateStorage: StateStorage;
    private readonly attachedProfilesStorageKey : string;
    private readonly compareProfilePillClass = "attached-profile-comparison";

    constructor(comparedProfileProps: IComparedProfilesProps) {
        this.comparedProfilesProps = comparedProfileProps;
        this.stateStorage = new StateStorage();
        this.attachedProfilesStorageKey = `${currentAttachedProfilesStorageKey}${comparedProfileProps.primaryProfileId}`;

        this.buildComparedProfilesPills();
    }

    public buildComparedProfilesPills() {
        let html = "";

        let selectedProfiles = this.stateStorage.load<SelectedProfileModel[]>(`${this.attachedProfilesStorageKey}`);

        if (this.comparedProfilesProps.subtoolsToExclude?.length > 0 &&
            selectedProfiles?.length > 0) {
            selectedProfiles = selectedProfiles.filter(profile => !this.comparedProfilesProps.subtoolsToExclude
                .some(excluded => excluded.toString() == profile.subtoolId));
        }

        if (selectedProfiles) {
            selectedProfiles.map((item: SelectedProfileModel) => {
                html += `<button class="btn btn-default btn-comparison ${this.compareProfilePillClass}" data-id-attached="${item.profileId}" data-subtoolid="${item.subtoolId}">${item.subtoolName}: ${item.profileName}
                            <svg class="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <use xlink:href="/dist/icons/sprite.symbol.svg#remove"></use>
                            </svg>
                        </button>`;
            });
        }

        const container = $(this.comparedProfilesProps.comparedProfilesContainerSelector);
        $(`.${this.compareProfilePillClass}`).remove();
        container.append(html);

        this.initPills();
    }

    public getComparedProfileIds() {
        const comparisonList: string[] = [];

        $(`.${this.compareProfilePillClass}`).each((idx: number, element: HTMLElement) => {
            const idAttached = $(element).data("id-attached");
            comparisonList.push(idAttached);
        });

        return comparisonList;
    }

    public getComparedProfileSubtoolIds() {
        const subtools: number[] = [];

        $(`.${this.compareProfilePillClass}`).each((idx: number, element: HTMLElement) => {
            const subtool = $(element).data("subtoolid") as string;
            subtools.push(parseInt(subtool));
        });

        return subtools;
    }

    private initPills(): void {
        const $this = this;

        $(`.${this.compareProfilePillClass}`).off("click").on("click", function (e: any) {
            const idToRemove = e.currentTarget.dataset.idAttached;
            $this.stateStorage.removeItem(idToRemove, `${$this.attachedProfilesStorageKey}`);

            $(this).remove();
            $this.comparedProfilesProps.comparedProfilesChangeCallback();
        });
    }
}
