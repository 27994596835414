import * as React from "react";
import Popover from "../ui-components/Popover";
import * as ReactDOMServer from 'react-dom/server'
import {FocusProfileModel} from "../../models/FocusProfileModel";

interface IProfileCountProps {
    profiles?: FocusProfileModel[];
    classes?: string;
}

export const ProfileCount = (props: IProfileCountProps) => {
    const profilesLoaded = props.profiles !== undefined;

    if(!profilesLoaded) return <></>

    if(props.profiles.length === 0) return <span className={`tag-profile-count ${props.classes}`}>{props.profiles.length}</span>;

    return <Popover content={getProfileList(props.profiles)}>
        <span className={`tag-profile-count ${props.classes}`}>{props.profiles.length}</span>
    </Popover>;
}

const getProfileList = (profiles: FocusProfileModel[]) => {
    const listItems = profiles.map(p => <p className="margin-bottom-xs" key={p.id}>{p.name}</p>);
    const tooltipHtml = <>{listItems}</>;

    return ReactDOMServer.renderToString(tooltipHtml);
}
