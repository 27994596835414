import { Spinner } from "../spinner-module";

export class ProfileSharingModal {

    private readonly spinnerController: Spinner;
    private readonly modalSelector: string;
    private readonly modalTitleSelector: string;
    private readonly modalApplySelector: string;

    private initModalCallback: () => void;
    private applyCallback: () => void;

    constructor(
        initModal: () => void,
        modalSelector: string,
        applyCallback: () => void,
        modalApplySelector: string,
        modalTitleSelector: string,
        modalSpinnerSelector: string,
        modalContainerSelector: string
    ) {

        this.spinnerController = new Spinner(modalSpinnerSelector, modalContainerSelector);
        this.initModalCallback = initModal;
        this.modalSelector = modalSelector;
        this.applyCallback = applyCallback;
        this.modalApplySelector = modalApplySelector;
        this.modalTitleSelector = modalTitleSelector;
    }

    public showSpinnner() {
        this.spinnerController.showSpinner();
    }

    public hideSpinner() {
        this.spinnerController.hideSpinner();
    }

    public showShareModal() {
        $(this.modalSelector).modal("show");

        $(this.modalSelector).find("#modal-reset").children("svg").hide();
        $(this.modalSelector).find(".modal-footer").hide();
    }

    public showNavigateBack() {
        $(this.modalSelector).find("#modal-reset").children("svg").show();
        $(this.modalSelector).find(".modal-footer").show();
    }

    public initChildPage() {

        $(this.modalSelector).find("#modal-reset").off("click").on("click", () => {
            this.initModalCallback();
        });

        $(this.modalApplySelector).off("click").on("click", () => {
            this.applyCallback();
        });

    }

    public setModalTitle(title: string): void {
        $(this.modalTitleSelector).text(title);
    }
}
