import * as React from "react";
import {DropdownModule} from "ditmer-embla";
import {SelectItemModel} from "../../models/SelectItemModel";
import {Localization} from "../../modules/localization-module";
import {DataFormat, GroupedDataFormat, IdTextPair, LoadingData, OptGroupData, OptionData, SearchOptions} from "select2";

interface Select2ComponentProps {
    multiple: boolean;
    options: SelectItemModel[];
    select2Id: string;
    itemSelected: (value: string) => void;
    itemUnselected: (value: string) => void;
    additionalSelect2Options?: Select2.Options;
    useTemplate?: boolean;
    disabled?: boolean;
    allowClear?: boolean;
    allowSecondaryValueSearch?: boolean;
    clearOnSelect?: boolean;
}

export class Select2Component extends React.Component<Select2ComponentProps> {

    private valueAdded: boolean = false;

    private setPrimaryAndSecondaryLabelValue(primary: string, secondary: string) {
        return $(`<div><span>${primary}</span> <span class="subtle">${secondary}</span></div>`);
    }

    private templateLoader(model: SelectItemModel[], data: (LoadingData | DataFormat | IdTextPair | GroupedDataFormat)) {
        const itemIndex = model.findIndex(r => r.value == data.id);
        const item = model[itemIndex];

        if (item){
            return this.setPrimaryAndSecondaryLabelValue(item.label, item.secondaryLabel);
        }
        else{
            return $(`<span class="subtle">${Localization.getText("Global_Select")}</span>`);
        }
    }

    private searchSecondaryValue(params: SearchOptions, data: OptGroupData | OptionData): OptGroupData | OptionData | null {
        const $this = this;

        // If there are no search terms, return all of the data
        if ($.trim(params.term) === '') {
            return data;
        }

        // Do not display the item if there is no 'text' property
        if (!data.text) {
            return null;
        }

        // `params.term` should be the term that is used for searching
        // `data.text` is the text that is displayed for the *primary* data object
        if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
            return $.extend({}, data, true);

        } else if ($this.props.allowSecondaryValueSearch) {

            const dataElement = data as OptionData;
            if(typeof dataElement === undefined) {//Not OptionData
                return null;
            }

            const dataElementIndex = $this.props.options.findIndex(r => r.value === dataElement.id);
            const item = $this.props.options[dataElementIndex];

            if (item.secondaryLabel.toLowerCase().includes(params.term.toLowerCase())) {
                return $.extend({}, data, true);
            }
        }

        return null;
    }

    componentDidMount() {
        const $this = this;

        const select2 = $(`#${$this.props.select2Id}`);

        let additionalSelect2Options: Select2.Options;
        if ($this.props.useTemplate) {
            additionalSelect2Options = {
                templateResult: (value: any) => {
                    const loadingData = value as LoadingData;
                    if (loadingData && loadingData.loading)
                        return loadingData.text;

                    return this.templateLoader(this.props.options, value);
                },
                templateSelection: (value: any) => {
                    return this.templateLoader(this.props.options, value);
                },
                matcher: (params, data) => this.searchSecondaryValue(params, data),
                language: {
                    noResults: function () {
                        return Localization.getText("Global_NoResult");
                    }
                }
            }
        }
        else {
            let defaultOptions : Select2.Options = {
                language: {
                    noResults: function () {
                        return Localization.getText("Global_NoResult");
                    }
                }
            }

            additionalSelect2Options = $.extend(true, {}, $this.props.additionalSelect2Options, defaultOptions);
        }

        new DropdownModule().init(select2.get(0), {
            placeholder: Localization.getText("Global_Select"),
            additionalSelect2Options: additionalSelect2Options,
            allowClear: this.props.allowClear === true
        });

        // @ts-ignore
        select2.on("select2:unselect", (e: any) => {
            const clickedValue = e.params.data.id;
            const selectedOption = $this.props.options.find(op => op.value === clickedValue);
            selectedOption.selected = false;
            $this.props.itemUnselected(clickedValue);
        });

        // @ts-ignore
        select2.on("select2:select", (e: any) => {
            this.valueAdded = true;

            const clickedValue = e.params.data.id;

            let selectedOption = $this.props.options.find(op => op.value === clickedValue);
            if (!selectedOption) {
                $this.props.options.push({ label: clickedValue, value: clickedValue, selected: true });
            }
            selectedOption = $this.props.options.find(op => op.value === clickedValue);

            if(!$this.props.clearOnSelect) {
                selectedOption.selected = true;
            }

            $this.props.itemSelected(clickedValue);
        });
    }

    private getSelected(): string[] | string {
        if (this.props.multiple) {
            return this.props.options.filter(option => option.selected).map(option => option.value);
        }
        else {
            const selectedIndex = this.props.options.findIndex(option => option.selected);
            if (selectedIndex >= 0)
                return this.props.options[selectedIndex].value;

            return undefined;
        }
    }

    private avoidDuplicates() {
        /*
        Avoid duplicates in multiple-tag-select2: https://github.com/select2/select2/issues/4298#issuecomment-315142164
        */
        const select2Option = `#${this.props.select2Id} option`;
        $(select2Option).each(function () {
            try {
                const val = $(this).val();
                $(this).siblings("[value='" + val + "']").remove();
            } catch (e) { }
        });
    }

    private renderSelect2(): JSX.Element {
        if (!this.valueAdded) {
            return (
                <select disabled={this.props.disabled}
                    multiple={this.props.multiple}
                    className="form-control"
                    key={this.props.select2Id}
                    name={this.props.select2Id}
                    id={this.props.select2Id}
                    onChange={() => {}}
                    value={this.getSelected()}>
                    {
                        this.props.options.map((option: SelectItemModel, index: number) => {
                            if (option.value) {
                                return <option key={`${this.props.select2Id}-${index}`}
                                               value={option.value}>{`${option.label}`}</option>
                            } else {
                                return <option key={`placeholder-${index}`}></option>
                            }
                        })
                    }
                </select>);
        }
    }

    render() {
        this.avoidDuplicates();

        return (
            <>
                {this.renderSelect2()}
            </>);
    }
}
