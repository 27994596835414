import {TagsModel} from "./TagsModel";

export class MultiRespondentEditModel {
    respondentType: number;
    respondentExpirationDate: string;
    respondentTags: TagsModel[] = [];
    profileTags: TagsModel[] = [];
    allRespondentsIsOwned : boolean = true;

    public copy(): MultiRespondentEditModel {
        const copy = new MultiRespondentEditModel();
        copy.respondentType = this.respondentType;
        copy.respondentExpirationDate = this.respondentExpirationDate;
        copy.respondentTags = [];
        copy.profileTags = [];

        this.respondentTags.forEach(rt => {
           const copiedTagsModel = Object.assign(new TagsModel(), rt);
           copiedTagsModel.tags = Object.assign(new Array<string>(), rt.tags);
           copy.respondentTags.push(copiedTagsModel);
        });

        this.profileTags.forEach(rt => {
            const copiedTagsModel = Object.assign(new TagsModel(), rt);
            copiedTagsModel.tags = Object.assign(new Array<string>(), rt.tags);
            copy.profileTags.push(copiedTagsModel);
        });

        return copy;
    }
}
