import { GarudaApi } from "../../infrastructure/garudaApi";
import { NoteModel } from "../../models/NoteModel";
import { viewTypePrivate, viewTypePublic } from "../../models/constants/noteConstants";
import { NotificationModule } from "ditmer-embla";
import { Localization } from "../localization-module";
import {DeleteNoteOtherUserModel} from "../../models/DeleteNoteOtherUserModel";

export class ThreadNoteModule {

    private readonly garudaApi: GarudaApi;
    private readonly noteBodySelector: string = ".note-body";
    private readonly noteBodyContentSelector: string = ".note-body-content";
    private readonly threadNoteTextAreaSelector: string = "thread-note-textarea";
    private readonly threadNotePrivateNoteCheckboxSelector: string = "private-note";
    private readonly threadNoteRowSelector: string = "thread-note-row";
    private readonly threadContextMenuBtnSelector: string = "#threadnote-context-menu-btn";

    constructor() {
        this.garudaApi = new GarudaApi();
    }

    public initThreadNotes(reloadNotesCallback: (threadId?: string) => Promise<void>) {

        $(".submit-note").off('click').on('click', (event) => {
            event.preventDefault();

            const noteModel = this.createNoteModelFromForm(event);

            if (!noteModel.noteBody || noteModel.noteBody.trim() === ""){
                const message = Localization.getText("Empty_Note_Warning");
                NotificationModule.showErrorSmall(message);
            }
            else{
                this.garudaApi.createNote(noteModel).then(() => {
                    const message = (!noteModel.noteId || noteModel.noteId.length === 0) ?
                        Localization.getText("Global_Note_Created") :
                        Localization.getText("Global_Note_Saved");
                    reloadNotesCallback(noteModel.threadId).then(this.notifySaved(message));
                })
            }
        })

        $(".delete-note").off('click').on('click', (event) => {
            event.preventDefault();

            const noteId = Number(event.currentTarget.getAttribute('data-note-id'));
            const threadId = event.currentTarget.getAttribute("data-thread-id");

            this.garudaApi.deleteNote(noteId).then(() => {
                reloadNotesCallback(threadId).then(this.notifySaved(Localization.getText("Global_Note_Deleted")));
            })
        })

        $(".delete-note-other-user").off('click').on('click', (event) => {
            event.preventDefault();

            const noteId = Number(event.currentTarget.getAttribute('data-note-id'));
            const userId = event.currentTarget.getAttribute("data-user-id");
            const threadId = event.currentTarget.getAttribute("data-thread-id");


            const deleteNodeFromOtherUser = new DeleteNoteOtherUserModel(noteId,userId);

            this.garudaApi.deleteNoteOtherUser(deleteNodeFromOtherUser).then(() => {
                reloadNotesCallback(threadId).then(this.notifySaved(Localization.getText("Global_Note_Deleted")));
            })
        })



        $(".change-note-visibility").off('click').on('click', (event) => {
            event.preventDefault();

            const isPrivate = event.currentTarget.getAttribute('data-note-visibility');
            const setVisibilityTo = (isPrivate === 'True') ? viewTypePublic : viewTypePrivate;

            const noteModel = this.createNoteModel(event.currentTarget, setVisibilityTo);

            this.garudaApi.updateThreadNote(noteModel).then(() => {
                const message = `${Localization.getText("Global_Note_ChangedTo")} ${
                    setVisibilityTo === viewTypePrivate ?
                        Localization.getText("Global_Private") :
                        Localization.getText("Global_Public")}`;
                reloadNotesCallback(noteModel.threadId).then(this.notifySaved(message));
            })
        })

        $(".edit-thread-note").off('click').on('click', (event) => {
            event.preventDefault();

            this.initEditThreadNote(event.currentTarget, reloadNotesCallback);
        })

        $(".note-body-content").off('click').on('click', (event) => {
            event.preventDefault();

            this.initEditThreadNote(event.currentTarget, reloadNotesCallback);
        })
    }

    private createNoteModelFromForm(event: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) {
        const form = event.currentTarget.closest("form");

        const textArea = form.elements.namedItem(this.threadNoteTextAreaSelector);
        const privateNoteCheckbox = form.elements.namedItem(this.threadNotePrivateNoteCheckboxSelector);
        const privateNote = $(privateNoteCheckbox).is(":checked") as boolean;

        const noteId = String($(textArea).data("note-id"));
        const noteBody: string = $(textArea).val() as string;
        const threadId = String($(textArea).data("thread-id"));
        const viewType = (privateNote === true) ? viewTypePrivate : viewTypePublic;

        return new NoteModel(threadId, noteId, noteBody, viewType);
    }

    private async initEditThreadNote(eventTarget: HTMLElement, reloadNotesCallback: (threadId?: string) => Promise<void>) {

        if ($(".thread-note-is-editing")[0]) {
            return;
        }

        const isPrivate = eventTarget.getAttribute('data-note-visibility');
        const setVisibilityTo = (isPrivate === 'True') ? viewTypePrivate : viewTypePublic;

        const noteModel = this.createNoteModel(eventTarget, setVisibilityTo);

        const partial = await this.garudaApi.editThreadNotePartial(noteModel);
        const noteBodyElement = this.getNoteBodyElement(eventTarget);

        $(this.threadContextMenuBtnSelector).attr("disabled", "disabled");

        $(noteBodyElement).empty();
        $(noteBodyElement).append(partial);

        $(".submit-note").off('click').on('click', (event) => {
            event.preventDefault();

            const noteModel = this.createNoteModelFromForm(event);

            if (!noteModel.noteBody || noteModel.noteBody.trim() === ""){
                const message = Localization.getText("Empty_Note_Warning");
                NotificationModule.showErrorSmall(message);
            }
            else{
                this.garudaApi.updateThreadNote(noteModel)
                    .then(() => {
                        reloadNotesCallback(noteModel.threadId)
                            .then(this.notifySaved(Localization.getText("Global_Note_Saved")));
                    });
            }
        })
    }

    private notifySaved(message: string) {
        return () => {
            NotificationModule.showSuccessSmall(message);
        }
    }

    private createNoteModel(eventTarget: HTMLElement, setVisibilityTo: string) {
        const threadId = String(eventTarget.getAttribute('data-thread-id'));
        const noteId = String(eventTarget.getAttribute('data-note-id'));
        const noteBody = this.getNoteBodyElement(eventTarget).querySelector(this.noteBodyContentSelector).textContent;

        return new NoteModel(threadId, noteId, noteBody, setVisibilityTo);
    }

    private getNoteBodyElement(eventTarget: HTMLElement) {

        let bodyElement: HTMLElement | null = eventTarget;

        while (bodyElement && !bodyElement.classList.contains(this.threadNoteRowSelector)) {
            bodyElement = bodyElement.parentElement;
        }

        return bodyElement.querySelector(this.noteBodySelector);
    }
}
