declare global {
    interface Window {
        showTooltip: (event: any, headertext: string, subtleHeaderText: string, flatContent: string, items: string[]) => void,
        showTooltipWithValueBox: (event: any, headertext: string, valueboxText: string, valueboxPositive : boolean, flatContent: string) => void;
        hideTooltip: () => void;
    }
}

export class DummyTooltipModule {
    public init() {
        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
        this.showTooltipWithValueBox = this.showTooltipWithValueBox.bind(this);

        window.showTooltip = this.showTooltip;
        window.hideTooltip = this.hideTooltip;
        window.showTooltipWithValueBox = this.showTooltipWithValueBox;
    }

    private showTooltip() {
        return;
    }

    private showTooltipWithValueBox () {
        return;
    }

    private hideTooltip() {
        return;
    }
}



