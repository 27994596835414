import * as React from "react";
import { renderComponent } from "./react/react-helper";
import { GarudaApi } from "./infrastructure/garudaApi";
import { NotificationModule } from "ditmer-embla";
import { Localization }  from "./modules/localization-module";
import { EditRespondentsTagsApp } from "./react/respondent-components/RespondentsTagsApp";
import { RespondentListModule } from "./modules/respondent-list-module";
import { MultiRespondentEditModel } from "./models/tags/MultiRespondentEditModel";
import { ToolEnum } from "./models/enums/toolEnum";
import {SubToolEnum} from "./models/enums/subToolEnum";

export const initializeEditTagsReactApp = (
    respondentListModule: RespondentListModule,
    multiRespondentEditModel : MultiRespondentEditModel,
    modalSelector: string,
    modalApplySelector: string,
    subtool: SubToolEnum) => {
    const reactContainer = document.getElementById("edit-tags-react-app");

    renderComponent((<EditRespondentsTagsApp
            multiRespondentEditModel={multiRespondentEditModel}
            modalSelector={modalSelector}
            saveCallbackButtonSelector={modalApplySelector}
            saveCallback={() => refreshRespondentList(respondentListModule, modalSelector)}
            showProfileTags={respondentListModule.tool !== ToolEnum.All}
            subtool={subtool}
        />
    ), reactContainer);
};

const refreshRespondentList = (respondentListModule: RespondentListModule, modalSelector: string) => {
    const api = new GarudaApi();

    respondentListModule.hideList();

    api.getRespondentListPartial(respondentListModule.tool, respondentListModule.subtool, respondentListModule.limit).then((html: string) => {

        $(modalSelector).modal("hide");
        NotificationModule.showSuccessSmall(Localization.getText("PersonalProfile_Editted"));

        $(respondentListModule.respondentListContainer).html(html);

        respondentListModule.initTable();
        respondentListModule.showList();
    });
}
