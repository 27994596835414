import * as React from "react";

interface ICustomTooltipProps {
    containerIdSelector?: string;
}

export const CustomTooltip = (props: ICustomTooltipProps) => {
    const containerId = props.containerIdSelector && props.containerIdSelector.length > 0
        ? props?.containerIdSelector
        : "custom-tooltip-container";

    return (
        <>
            <div id={containerId}>
                <div className="default-custom-tooltip custom-tooltip hidden">
                    <div className="tooltip-header-container">
                        <h4 className="bold tooltip-header"></h4>
                        <h5 className="tooltip-subtle-header"></h5>
                    </div>
                    <hr className="tooltip-header-container"/>
                    <div className="tooltip-content-container"></div>
                </div>

                <div className="default-custom-tooltip custom-tooltip-split-content">
                    <div className="split-content">
                        <div className="margin-right-m"></div>
                        <div className="bold"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
