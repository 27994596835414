import {GarudaApi} from "./infrastructure/garudaApi";
import {DropdownModule, NotificationModule} from "ditmer-embla";
import {Localization} from "./modules/localization-module";
import {Modal} from "./modules/modal-module";
import { TransferOwnershipPostModel } from "./models/respondent/TransferOwnershipPostModel";
import { RespondentListModule } from "./modules/respondent-list-module/respondentListModule";

export class TransferOwnerships {
    private readonly garudaApi: GarudaApi;
    private modal: Modal;
    private readonly respondents: string[];
    private profileIds: string[];
    private userId: string;
    private keepSharing: boolean;

    private readonly removeMultiSharingsSelector: string = "#remove-multi-sharings";
    private readonly userSelectSelector: string = "#transfer-ownership-user";
    private readonly transferedOwnershipUsersSelector: string = ".transfered-ownership-users";
    
    private readonly modalSelector: string;

    private readonly respondentListModule: RespondentListModule;

    constructor(respondentList: RespondentListModule, respondents: string[], profileIds: string[], modalSelector: string) {
        this.garudaApi = new GarudaApi();
        this.respondents = respondents;
        this.profileIds = profileIds;

        this.modalSelector = modalSelector;
        this.keepSharing = false;

        this.respondentListModule = respondentList;
    }

    public async init(): Promise<void> {
        this.modal = new Modal(
            ".transfer-ownership-btn",
            this.modalSelector,
            () => this.getTransferOwnershipPartial(),
            () => this.initPartial(),
            () => this.transferOwnership());

        await this.modal.init();
    }

    public async openModal(): Promise<void> {
        this.modal.open();
        await this.modal.loadModalContent();
    }

    private async getTransferOwnershipPartial(): Promise<string> {
        return await this.garudaApi.getTransferOwnershipModal(this.profileIds);
    }

    private initPartial(): void {
        const $this = this;
        $this.initUserSelect();

        $(this.removeMultiSharingsSelector).off("click").on("click", async function (event) {
            event.preventDefault();
            await $this.garudaApi.removeMultiProfileSharing($this.profileIds);
            await $this.modal.loadModalContent();
            NotificationModule.showSuccessSmall(Localization.getText("Global_SharingsRemoved"));
        });
    }

    private initSwitch() {
        $(".keep-sharing-switch").off("click").on("click", (e) => {
            this.keepSharing = $(e.currentTarget).prop("checked");
        });
    }

    private async initUserSelect() {
        const $this = this;
        new DropdownModule().init(this.userSelectSelector, {
            searchable: true,
            allowClear: true,
            placeholder: Localization.getText("ProfilSharingModal_Choose"),
            additionalSelect2Options: {
                language: {
                    noResults: function () {
                        return Localization.getText("Global_NoResult");
                    }
                }
            }
        });

        $(this.userSelectSelector).off("select2:select").on("select2:select", function (e) {
            const userId = e.params.data.id;
            $this.userId = userId;
            const userName = $(e.params.data.element).data("name");
            const userEmail = $(e.params.data.element).data("email");

            const transferOwnershipPostModel = new TransferOwnershipPostModel($this.respondents, false, userId, userName, userEmail);

            $this.garudaApi.getTransferOwnershipUserComponent(transferOwnershipPostModel).then((value) => {
                $($this.transferedOwnershipUsersSelector).empty();
                $($this.transferedOwnershipUsersSelector).prepend(value);
                $this.initSwitch();
            });

            $($this.userSelectSelector).val(null).trigger('change');
        });
    }

    private async transferOwnership() {
        const transferOwnershipPostModel = new TransferOwnershipPostModel(this.respondents, this.keepSharing, this.userId);
       await this.garudaApi.transferOwnership(transferOwnershipPostModel).then(() => {
            this.modal.close();
            this.respondentListModule.hideList();
            NotificationModule.showSuccessSmall(Localization.getText("Respondent_Ownership_Transfered"));

            this.garudaApi.getRespondentListPartial(this.respondentListModule.tool, this.respondentListModule.subtool, this.respondentListModule.limit)
                .then((html: string) => {
                    $(this.respondentListModule.respondentListContainer).html(html);
                    this.respondentListModule.initTable();
                    this.respondentListModule.showList();
                });
        });
    }
}
