import {StateStorage} from "../../infrastructure/stateStorage";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {ProfileSettingModel} from "../../models/settings/ProfileSettingModel";
import {
    settingsGeneralProfileConstant,
    settingsHighlightedProfileConstant,
    settingsProfilesConstant
} from "../../models/constants/settingsConstants";
import {DropdownModule} from "ditmer-embla";
import {Localization} from "../localization-module";

interface IGraphSwitchModule {
    traitSwitchChangedCallback?: () => void;
}

export class GraphSwitchModule {
    private readonly stateStorage: StateStorage;
    private readonly garudaApi: GarudaApi;

    private props : IGraphSwitchModule;

    constructor(settings: IGraphSwitchModule) {
        this.props = settings;
        this.stateStorage = new StateStorage();
        this.garudaApi = new GarudaApi();
    }

    public async initSettings(profileId : string) {
        const settings : ProfileSettingModel[] = await this.garudaApi.getAdjustmentProfileSettings(profileId);
        settings.forEach(setting => {
            this.setSwitchValue(setting.type, setting.value);
        });
    }

    public init() {
        const $this = this;
        $this.bindSettingSwitch();
        $this.bindSettingRadio();
        $this.bindSettingDropdown();
        $this.initProfileSwitches();
    }

    public getSwitchValue(key : string): boolean {
        return this.stateStorage.load<boolean>(key) ?? false;
    }

    public setSwitchValue(key : string, value: boolean) {
        this.stateStorage.save(key, value);
    }

    private bindSettingSwitch = () => {
        const $this = this;
        $(".setting-switch").on("change", async function () {
            const switchValue = $(this).is(":checked");
            const settingKey = $(this).attr("setting-key");
            const settingConnectedKey = $(this).attr("setting-connectedkey");
            const typeKey = $(this).attr("setting-enum");
            const isSwitchHeader = $(this).hasClass("switch-header");

            if(settingConnectedKey === settingsGeneralProfileConstant) {
                $this.handleProfileSettings(settingKey, switchValue, isSwitchHeader);
            }

            if(isSwitchHeader) {
                const values: ProfileSettingModel[] = [];
                const checkBoxesToUpdate = $(`.setting-switch[setting-connectedkey='${settingKey}']`);
                checkBoxesToUpdate.each(function () {
                    $(this).prop("checked", switchValue);
                    $this.setSwitchValue(typeKey, switchValue);
                    values.push({
                        key: $(this).attr("setting-key"),
                        value: $(this).is(":checked"),
                        type: $(this).attr("setting-enum")
                    });
                })
                await $this.garudaApi.updateGenerelProfileSettingList(values);
            } else {
                $this.setSwitchValue(typeKey, switchValue);
                await $this.garudaApi.updateGenerelProfilesetting(settingKey, switchValue);
            }

            $this.props.traitSwitchChangedCallback();
        });
    }

    private handleProfileSettings = (settingKey: string, switchValue: boolean, isSwitchHeader: boolean) => {
        if (switchValue) {
            // Clears all element from the storage if header is clicked
            // otherwise, only clear clicked element
            if(isSwitchHeader) {
                this.stateStorage.clear(settingsProfilesConstant);
            } else {
                const profiles = this.stateStorage.load<string[]>(settingsProfilesConstant) ?? [];
                const profileIndex = profiles.indexOf(settingKey)
                if (profileIndex !== -1) profiles.splice(profileIndex, 1) // Remove the object from the array if it exists
                this.stateStorage.save(settingsProfilesConstant, profiles);
            }
        } else {
            if(isSwitchHeader) {
                const profiles = this.getProfileIds();
                this.stateStorage.save(settingsProfilesConstant, profiles);
            } else {
                const profiles = this.stateStorage.load<string[]>(settingsProfilesConstant) ?? [];
                if (profiles.indexOf(settingKey) === -1) profiles.push(settingKey); // If the object is not already in the list, add it
                this.stateStorage.save(settingsProfilesConstant, profiles);
            }
        }
    }

    private getProfileIds = (): string[] => {
        const profiles: string[] = [];
        $(`input[setting-connectedkey="${settingsGeneralProfileConstant}"]`).each(function(i) {
            profiles.push($(this).attr("setting-key"));
        });

        return profiles.filter(p => p !== settingsGeneralProfileConstant);
    }

    private bindSettingRadio = () => {
        const $this = this;
        $(".setting-radio").on("change", async function () {
            const switchValue = $(this).is(":checked");
            const typeKey = $(this).attr("setting-enum");
            const settingIdentifier = $(this).attr("setting-identifier");
            $this.setSwitchValue(typeKey, switchValue);

            const values: ProfileSettingModel[] = [];
            const radioButtonsUpdated = $(`.setting-radio[setting-identifier='${settingIdentifier}']`);
            radioButtonsUpdated.each(function() {
                values.push({
                    key: $(this).attr("setting-key"),
                    value: $(this).is(":checked"),
                    type: $(this).attr("setting-enum")
                });
            })

            await $this.garudaApi.updateGenerelProfileSettingList(values);

            $this.props.traitSwitchChangedCallback();
        });
    }

    private bindSettingDropdown = () => {
        const $this = this;
        const dropdownSelector = $('.setting-dropdown');

        const val = this.stateStorage.load(settingsHighlightedProfileConstant);
        if (val !== null) {
            dropdownSelector.val(this.stateStorage.load(settingsHighlightedProfileConstant));
        }

        new DropdownModule().init(dropdownSelector[0], {
            allowClear: true,
            placeholder: Localization.getText("Search_Respondent")
        });

        dropdownSelector.off("select2:select").on("select2:select", function (e) {
            $this.stateStorage.save(settingsHighlightedProfileConstant, $(this).val());
            $this.props.traitSwitchChangedCallback();
        });

        dropdownSelector.off("select2:clear").on("select2:clear", function (e) {
            $this.stateStorage.clear(settingsHighlightedProfileConstant);
            $this.props.traitSwitchChangedCallback();
        });
    }

    private initProfileSwitches = () => {
        const profiles = this.stateStorage.load<string[]>(settingsProfilesConstant) ?? [];
        if (profiles.length === 0) return;

        const elements = $(`input[setting-connectedkey="${settingsGeneralProfileConstant}"]`);

        // all profileIds are in SessionStorage, switch off all switches, including header
        if (profiles.length === elements.length-1) { // -1 added due to header being included in elements
            elements.each(function() {
                $(this).prop('checked', false);
            });
        } else {
            elements.each(function() {
                const profileId = $(this).attr("setting-key");
                if (profiles.includes(profileId)) {
                    $(this).prop('checked', false);
                }
            });
        }
    }
}
