import {ComparedProfilesModule} from "../compared-profiles-module";
import {SelectPagingModule} from "../select-paging-module";
import {GrafModule} from "../graf-module";
import {ProfileCompareWizard} from "../profile-compare-module/profileCompareWizard";
import {InterpretationPanel} from "./interpretationPanel";
import {NoteModule} from "../note-module";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {GraphicTypeEnum} from "../../models/enums/graphTypeEnum";
import {ThreadNoteModule} from "../thread-note-module";
import {GraphSwitchModule} from "../graph-switch-module";
import {ReportModule} from "../report-module";
import {PluralityEnum} from "../../models/enums/pluralityEnum";
import {ProfileCard} from "../../profileCard";
import {ToolEnum} from "../../models/enums/toolEnum";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {Modal} from "../modal-module";
import {CustomTooltipModule} from "../custom-tooltip-module";
import {SvgClickModule} from "../svg-click-module";
import {copJobProfileEditUrl} from "../../models/constants/apiConstants";
import {AnalysisPageEnum} from "../../models/enums/analysisPageEnum";
import {TraitNavigator} from "../../traitNavigator";

export class Interpretation {
    private readonly interpretationPanel: InterpretationPanel;
    private readonly comparedProfilesModule: ComparedProfilesModule;
    private readonly profileCompareWizard: ProfileCompareWizard;
    private readonly grafContainerElementSelector: string = ".graf-container";
    private readonly grafElementSelector: string = ".grafs";
    private readonly selectSelector: string = "#Traits";
    private readonly leftPagerSelector: string = ".left-select";
    private readonly rightPagerSelector: string = ".right-select";
    private readonly scoreChangedLabelSelctor: string = ".label-score-change";
    private readonly profileId: string;
    private readonly garudaApi: GarudaApi;
    private readonly reportModule: ReportModule;
    private readonly noteModule: NoteModule;
    private readonly subtool: SubToolEnum;
    private readonly customTooltipContainerIdSelector: string = "#trait-custom-tooltip";
    private readonly includeGroup: boolean;
    private changeScore: boolean = false;
    private readonly changeSvgScore = ".btn-change-svg-score";
    private readonly closeChangeSvgScore = ".btn-close-change-svg-score";
    private readonly editJobProfileSelector = ".edit-jobprofile-button";

    private traitNavigation: TraitNavigator;
    private selectPagingModule: SelectPagingModule;
    private graphSwitchesModule: GraphSwitchModule;
    private readonly svgClickedModule: SvgClickModule;

    constructor(tool: ToolEnum, subTool: SubToolEnum, excludedSubtools?: SubToolEnum[],
                includeGroup: boolean = false) {
        this.traitNavigation = new TraitNavigator();
        this.garudaApi = new GarudaApi();
        this.interpretationPanel = new InterpretationPanel();
        this.subtool = subTool;
        this.includeGroup = includeGroup;

        this.profileId = $("#ProfileId").val() as string;

        this.reportModule = new ReportModule({
            openButtonSelector: "#btn-open-report-modal",
            tool: tool,
            subtool: subTool,
            primaryProfileId: this.profileId,
            outerCustomTooltipSelector: this.customTooltipContainerIdSelector
        });

        this.svgClickedModule = new SvgClickModule({
            showGraphsCallback: async () => {
                this.showGraph(subTool);
                await this.interpretationPanel.initInterpretations(this.profileId, this.getTraitId(), this.comparedProfilesModule.getComparedProfileIds());
                await this.noteModule.initNotes();
                new ThreadNoteModule().initThreadNotes(() => this.init());

            }
        });
        this.svgClickedModule.init();

        this.profileCompareWizard = new ProfileCompareWizard(
            this.profileId,
            () => {
                this.comparedProfilesModule.buildComparedProfilesPills();
                this.init();
            },
            subTool,
            tool,
            excludedSubtools ?? [],
            this.includeGroup
        );

        this.comparedProfilesModule = new ComparedProfilesModule({
            comparedProfilesContainerSelector: "#attached-profile-container",
            comparedProfilesChangeCallback: () => this.init(),
            primaryProfileId: this.profileId,
            subtoolsToExclude: []
        });

        this.noteModule = new NoteModule();
        new ProfileCard(this.profileId).init();
    }

    public async init(): Promise<void> {
        await this.reportModule.initialize([this.profileId], PluralityEnum.Single, false);

        const traitId = this.getTraitId();
        if (traitId) {
            await this.initTraits();
        }

        await this.showContent();
        this.initEvents();
        this.initCustomTooltip();
    }

    private initCustomTooltip = () => {
        const tooltipModule = new CustomTooltipModule({
            tooltipContainerIdSelector: this.customTooltipContainerIdSelector
        });
        tooltipModule.init();
    }

    private showGraph(subtool: SubToolEnum): void {
        const profileId = this.profileId;
        const traitId = this.getTraitId();
        const comparedProfileIds = this.comparedProfilesModule.getComparedProfileIds();

        const grafModule = new GrafModule({
            profileId: profileId,
            typeIds: [traitId],
            grafContainerSelector: this.grafContainerElementSelector,
            graphicType: GraphicTypeEnum.graphSingle,
            graphCombined: false,
            graphCaption: false,
            analysisPage: AnalysisPageEnum.Interpretation,
            subToolEnum: subtool,
            changeScore: this.changeScore,
            showContextMenu: true,
            afterContentRendered: () => this.initSvgChanged()
        });

        grafModule.init(comparedProfileIds);
    }

    private async initTraits(): Promise<void> {

        this.selectPagingModule = new SelectPagingModule({
            selectSelector: this.selectSelector,
            leftPagerSelector: this.leftPagerSelector,
            rightPagerSelector: this.rightPagerSelector,
            selectChangedCallback: () => this.setTraitId()
        });

        this.selectPagingModule.init();

        this.graphSwitchesModule = new GraphSwitchModule({
            traitSwitchChangedCallback: () => {
                this.showGraph(this.subtool)
                this.showContent();
            }
        });

        const adjustModal = new Modal(
            "#adjust-modal-button",
            "#adjust-modal",
            () => this.garudaApi.getAdjustmentModal(this.profileId, this.comparedProfilesModule.getComparedProfileIds().length === 0),
            () => this.graphSwitchesModule.init()
        );

        adjustModal.init();

        this.graphSwitchesModule.init();
        this.showGraph(this.subtool);
    }

    private async showContent() {
        await this.noteModule.setNoteCount(this.profileId);
        const traitId = this.getTraitId();
        const comparedProfileIds = this.comparedProfilesModule.getComparedProfileIds();
        await this.interpretationPanel.initInterpretations(this.profileId, traitId, comparedProfileIds);
        this.noteModule.initNotes();
        new ThreadNoteModule().initThreadNotes(() => this.init());
        this.traitNavigation.initTraitNavigator();
    }

    private initSvgChanged() {
        const svgChanged = $("#svg-score-change").data("scorechange");

        if (svgChanged == "True" && !this.changeScore) {
            $(this.scoreChangedLabelSelctor).prop("hidden", false);
        }
        else {
            $(this.scoreChangedLabelSelctor).prop("hidden", true);
        }
    }

    private initEvents(): void {
        const $this = this;

        $(".own-comment-replaces-interpretation").off().on("click", async function(event) {
            if (event.target.tagName === "INPUT") {
                const state = $(this).is(":checked");
                const settingsId = $(this).data("settings-id");

                return $this.garudaApi.updateOwnCommentReplacesInterpretation(settingsId, state);
            }
        });

        $(this.changeSvgScore).off("click").on("click", async () => {
            this.changeScore = true;
            this.showGraph(this.subtool);
            $(this.changeSvgScore).prop("hidden", true);
            $(this.scoreChangedLabelSelctor).prop("hidden", true);
            $(this.closeChangeSvgScore).prop("hidden", false);
        });

        $(this.closeChangeSvgScore).off("click").on("click", async () => {
            this.changeScore = false;
            this.showGraph(this.subtool);
            $(this.changeSvgScore).prop("hidden", false);
            $(this.closeChangeSvgScore).prop("hidden", true);
            this.initSvgChanged();
            // initiate notes again so note context button is again functional. I cannot find a reason for why note context button is disabled
            // TODO: Find the underlying problem
            this.noteModule.initNotes();
            new ThreadNoteModule().initThreadNotes(() => this.init());
        });

        $(this.editJobProfileSelector).off("click").on("click", async () => {
            window.location.href = copJobProfileEditUrl + this.profileId;
        });
    }

    private getTraitId(): string {
        return $(this.grafElementSelector).attr("id");
    }

    private async setTraitId() {
        const traitId = $(this.selectSelector).val() as string;
        $(this.grafElementSelector).attr("id", traitId);
        await this.init();
    }
}
