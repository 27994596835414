import {UserRoleModel} from "../../models/users/UserRoleModel";
import {Localization} from "../localization-module";
import {DatetimePickerModule, DatetimePickerTypeEnum, DropdownModule, NotificationModule} from "ditmer-embla";
import {inputDateFormat} from "../../models/constants/dateFormat";
import {GarudaApi} from "../../infrastructure/garudaApi";
import moment = require("moment");
import {ProfileSharingModal} from "../profile-sharing-module";
import {InviteUserModule} from "../invite-user-module";

interface IUserSharingCustomization {
    profileIds: string[];
    modalContainerSelector: string;
    sharedUsersRoleSelector: string;
    openSharingModal: () => void;
}

export class UserSharingCustomization {
    private readonly garudaApi: GarudaApi;
    private props: IUserSharingCustomization;
    private customizedDatePicker: DatetimePickerModule;

    public profileSharingModal: ProfileSharingModal;
    public inviteUserModule: InviteUserModule;

    private customizedDate: string;
    private customizedDateOpened: boolean;
    private newUserCustomizeModalOpen: boolean;
    private userId: string;

    private readonly customizeDatePickerSelector: string = "#datepickercustomized"

    constructor(settings: IUserSharingCustomization) {
        this.props = settings;
        this.garudaApi = new GarudaApi();
        this.customizedDateOpened = false;
    }

    public openCustomizeSharing(email: string, name: string, userId: string, newUser: boolean = false, userRoleModels: UserRoleModel[] = null)
    {
        this.userId = userId;
        this.profileSharingModal.setModalTitle(Localization.getText("User_Customized_Sharing"));
        this.profileSharingModal.showSpinnner();
        this.showCustomizeSharing(this.userId, email, name, newUser, userRoleModels);
        this.newUserCustomizeModalOpen = newUser;
    }

    public async showCustomizeSharing(userId: string, userEmail: string, userName: string, newUser: boolean, userRoleModels: UserRoleModel[] = null) {
        this.userId = userId;
        this.profileSharingModal.showNavigateBack();

        const htmlBody = newUser
            ? await this.garudaApi.customizeNewUserProfileSharing(userRoleModels, this.props.profileIds[0], userEmail, userName, this.inviteUserModule.GetTimeLimit(userEmail, userName))
            : await this.garudaApi.customizeProfileSharingSharing(this.props.profileIds[0], userEmail, userName, this.userId);

        await $(this.props.modalContainerSelector).html(htmlBody);

        this.profileSharingModal.hideSpinner();

        this.initCustomizeDatepicker(newUser);
        this.initCustomizedRoleSelect();

        this.profileSharingModal.initChildPage();
    }

    public initCustomizedRoleSelect() {
        const $this = this;
        new DropdownModule().init(this.props.sharedUsersRoleSelector, {
            searchable: false
        });

        $(this.props.sharedUsersRoleSelector).off("select2:select").on("select2:select", function (e) {
            const roleId = e.params.data.id;
            $this.userId = $(this).data("user-id");

            if (roleId) {
                $this.garudaApi.updateProfileRole($this.props.profileIds[0], $this.userId, roleId).then(() => {
                    NotificationModule.showSuccessSmall(Localization.getText("ProfilSharingModal_SharingUpdated"));
                });
            }
        });
    }

    public initCustomizeDatepicker(newUser: boolean) {
        this.customizedDatePicker = new DatetimePickerModule(this.customizeDatePickerSelector, {
            type: DatetimePickerTypeEnum.Date,
            onSetDate: (event, inst) => newUser ? this.inviteUserModule.SetTimeLimit(event.date) : this.setCustomizedTimeLimit(event.date),
            allowClear: true,
            additionalMbscCalenderOptions: {
                clearText: Localization.getText("Global_Clear")
            }
        });

        if (this.customizedDatePicker.getValue() !== null)
        {
            newUser ? this.inviteUserModule.SetTimeLimit(this.customizedDatePicker.getValue()) : this.setCustomizedTimeLimit(this.customizedDatePicker.getValue())
        }
    }

    public async saveCustomizedSharing() {
        if(this.newUserCustomizeModalOpen) {
            this.newUserCustomizeModalOpen = false;
            await this.props.openSharingModal();
            NotificationModule.showSuccessSmall(Localization.getText("ProfilSharingModal_SharingUpdated"));
        }
        else if (typeof this.customizedDate != "undefined") {
            await this.garudaApi.setCustomizedProfileSharingTimeLimit(this.userId, this.props.profileIds[0], this.customizedDate);
            await this.props.openSharingModal();
            NotificationModule.showSuccessSmall(Localization.getText("ProfilSharingModal_SharingUpdated"));
        }
        else {
            NotificationModule.showErrorSmall(Localization.getText("Choose_Date"));
        }
    }

    public async setCustomizedTimeLimit(date: string | Date) {
        const datepickerDate = moment(this.customizedDatePicker.getValue(), inputDateFormat, true);
        this.customizedDate = moment(date, inputDateFormat, true).format();

        if (!this.customizedDateOpened || datepickerDate.format() === this.customizedDate) {
            this.customizedDateOpened = true;
            return;
        }

        if (!datepickerDate.isValid()) {
            this.customizedDateOpened = false;
            this.customizedDate = null;
        }
    }
}
