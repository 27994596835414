import {GarudaApi} from "../../infrastructure/garudaApi";
import {Localization} from "../localization-module";
import {Modal} from "../modal-module";
import {ToolEnum} from "../../models/enums/toolEnum";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {PluralityEnum} from "../../models/enums/pluralityEnum";
import {ReportModule} from "../report-module";
import {ProfileSharing} from "../../profileSharing";
import {getProfileUrl} from "../../infrastructure/apiHelper";
import {RespondentActions} from "./respondentActions";
import {profileStatusEnum} from "../../models/enums/profileStatusEnum";
import {EventLogModule} from "../../eventLogModule";

export class ProfileSelectorModule {
    private readonly garudaApi: GarudaApi;
    private readonly respondentActions: RespondentActions;
    private modal: Modal;
    private triggerElement: JQuery<HTMLElement>;
    private respondentName: string;

    constructor(triggerSelector: string,
                modalId: string,
                getProfileId: (clickedElement: JQuery<HTMLElement>) => string) {
        this.garudaApi = new GarudaApi();
        this.respondentActions = new RespondentActions(ToolEnum.All, SubToolEnum.None);

        this.modal = new Modal(
            triggerSelector,
            modalId,
            (triggerElement) => {
                this.triggerElement = triggerElement;
                this.respondentName = triggerElement.data("respondent-name");
                return this.garudaApi.getProfileSelectorModal(getProfileId(triggerElement));
            },
            () => {
                this.initButtons();
                this.initHeader();
            },
            (url) => { window.location.href = url; });
    }

    public initModal() {
        this.modal.init();
    }

    private initButtons() {
        this.initShowButton();
        this.initCreateReportButton();
        this.initShareButton();

        this.initEventlogProfileModal();

        this.initWithdrawConsent();
        this.initArchive();
        this.initDelete();
    }

    // Header text is also initialized in _RespondentListModals,
    // but is overwritten here depending on the loaded user
    private initHeader() {
        const headerText = `${Localization.getText("Respondent_ProfileSelector")} - ${this.respondentName}`;
        $("#profile-selector-modal").find(".modal-title").text(headerText)
    }

    private initShowButton() {
        const showButtonSelector = $(".profile-selector-show-button");

        showButtonSelector.off("click").on("click", async (event) => {
            const dataElement = $(event.currentTarget).closest(".card");
            const profileId = dataElement.data("profile-id");
            const profileName = dataElement.data("profile-name");
            const tool = dataElement.data("tool") as ToolEnum;
            const subTool = dataElement.data("subtool") as SubToolEnum;
            const profileStatus = dataElement.data("profile-status") as profileStatusEnum;

            window.location.href = getProfileUrl(profileId, tool, subTool, profileName, profileStatus);
        });
    }

    private initCreateReportButton() {
        const reportButtonSelector = $(".profile-selector-create-report-button");

        reportButtonSelector.off("click").on("click", async (event) => {
            const dataElement = $(event.currentTarget).closest(".card");
            const profileId = dataElement.data("profile-id");
            const profileName = dataElement.data("profile-name");
            const tool = dataElement.data("tool") as ToolEnum;
            const subTool = dataElement.data("subtool") as SubToolEnum;

            const reportModule = new ReportModule({
                openButtonSelector: "",
                tool: tool,
                subtool: subTool,
                outerCustomTooltipSelector: "#custom-tooltip-container"
            });

            await reportModule.initialize([profileId], PluralityEnum.Single, true, profileName);
            this.modal.close()
        });
    }

    private initShareButton() {
        const shareButtonSelector = $(".profile-selector-share-button");

        shareButtonSelector.off("click").on("click",  async (event) => {
            const dataElement = $(event.currentTarget).closest(".card");
            const profileId = dataElement.data("profile-id");
            const profileSharing = new ProfileSharing([profileId]);
            await profileSharing.openSharingModal();
            this.modal.close();
        });
    }

    public initEventlogProfileModal() {
        $(".open-eventlog-profile-modal-button").off("click").on("click", async (e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement, HTMLElement>) => {
            await this.openEventlog(e.currentTarget.dataset.profileId);
        });
    }

    private async openEventlog(id: string): Promise<void> {
        const eventlog = new EventLogModule(id, true);
        await eventlog.initModal();
        await eventlog.openModal();
    }

    public initWithdrawConsent() {
        const $this = this;
        const modalSelector = "#withdraw-consent-profile-modal";

        const withdrawConsentModal = new Modal(
            ".withdraw-consent-profile",
            modalSelector,
            (triggerElement : JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("PersonalProfile_WithdrawConsentModal_Title"), triggerElement),
            () => {
                this.modal.close();
            },
            () => {
                this.garudaApi.withdrawConsentForProfile(this.getChosenProfileIds()).then(() => {
                    window.location.reload();
                });
            }
        );
        withdrawConsentModal.init();
    }

    public initArchive() {
        const $this = this;
        const modalSelector = "#archive-profile-modal";

        const archivePersonsModal = new Modal(
            ".archive-profile",
            modalSelector,
            (triggerElement : JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("PersonalProfile_ArchiveModal_Title"), triggerElement),
            () => {
                this.modal.close();
            },
            () => {
                this.garudaApi.archiveProfile(this.getChosenProfileIds()).then(() => {
                    window.location.reload();
                });
            }
        );

        archivePersonsModal.init();
    }

    public initDelete() {
        const $this = this;
        const modalSelector = "#delete-profile-modal";

        const deletePersonsModal = new Modal(
            ".delete-profile",
            modalSelector,
            (triggerElement : JQuery<HTMLElement>) =>
                $this.setNamesInProfileActionModal(modalSelector, Localization.getText("PersonalProfile_DeleteModal_Title"), triggerElement),
            () => {
                this.modal.close();
            },
            () => {
                this.garudaApi.deleteRespondent(this.getChosenProfileIds()).then(() => {
                    window.location.reload();
                });
            }
        );

        deletePersonsModal.init();
    }

    private getChosenProfileIds(): string[] {
        const personprofileIds: string[] = [];
        const profileIdInputs = $(".profile-action-id");

        profileIdInputs.map(function () {
            personprofileIds.push($(this).val() as string);
        });

        return personprofileIds;
    }

    private setNamesInProfileActionModal(modalSelector: string,
                                         modalTitlePrefix: string,
                                         htmlElement?: JQuery<HTMLElement>): Promise<string> {
        const respondentNames: string[] = [];
        const profileIds: string[] = [];

        if (htmlElement !== undefined) {
            const card = htmlElement.closest(".card");
            respondentNames.push($(card).data("profile-name"));
            profileIds.push($(card).data("profile-id"));
        }

        return this.respondentActions.setModalData(modalSelector, modalTitlePrefix, respondentNames, profileIds);
    }
}
