import {SubToolEnum} from "../enums/subToolEnum";

export class RespondentCrudHelper {
    public canSetRespondentType(subtool: SubToolEnum): boolean {
        return subtool !== SubToolEnum.FocusDreamPosition &&
            subtool !== SubToolEnum.FocusJob &&
            subtool !== SubToolEnum.FocusMirror &&
            subtool !== SubToolEnum.FocusReference &&
            subtool !== SubToolEnum.FocusGroup &&
            subtool !== SubToolEnum.Job &&
            subtool !== SubToolEnum.Mirror &&
            subtool !== SubToolEnum.Reference
    }

    public canSetExpiryDate(subtool: SubToolEnum): boolean {
        return subtool !== SubToolEnum.FocusJob &&
            subtool !== SubToolEnum.FocusGroup &&
            subtool !== SubToolEnum.Job
    }

    public canSetRespondentTags(subtool: SubToolEnum): boolean {
        return subtool !== SubToolEnum.FocusGroup
    }
}
