import {
    DropdownModule,
} from "ditmer-embla";
import {Localization} from "../localization-module";

interface ISelectPagingModule {
    selectSelector: string;
    leftPagerSelector: string;
    rightPagerSelector: string;
    selectChangedCallback: () => void;
}

export class SelectPagingModule {
    private props : ISelectPagingModule;

    constructor(settings: ISelectPagingModule) {
        this.props = settings;
    }

    public init() {

        const $this = this;

        $(this.props.selectSelector).off("select2:select").on("select2:select", function (e) {
            $this.selectItem($(this).text());
            $this.initFields();
            $this.props.selectChangedCallback();
        });

        $(this.props.leftPagerSelector).off("click").on("click", function (e) {
            $this.selectItem($(this).text());
            $this.initFields();
            $this.props.selectChangedCallback();
        });

        $(this.props.rightPagerSelector).off("click").on("click", function (e) {
            $this.selectItem($(this).text());
            $this.initFields();
            $this.props.selectChangedCallback();
        });

        $this.initFields();
    }

    private initFields() {
        const selectedOption = $(this.props.selectSelector).find(':selected');
        const prev = selectedOption.prev()?.length === 1 ? selectedOption.prev() : $(this.props.selectSelector).children().last();
        const next = selectedOption.next()?.length === 1 ? selectedOption.next() : $(this.props.selectSelector).children().first();

        $(this.props.leftPagerSelector).children("span").text(prev.text());
        $(this.props.rightPagerSelector).children("span").text(next.text());
    }

    private selectItem(content: string) {
        const $this = this;

        $(this.props.selectSelector).children().each(function() {
            if ($(this).text().trim() === content.trim()) {
                $($this.props.selectSelector).val($(this).val());
            }
        });

        new DropdownModule().init(this.props.selectSelector, {
            additionalSelect2Options: {
                language: {
                    noResults: function () {
                        return Localization.getText("Global_NoResult");
                    }
                }
            }
        });
    }
}



