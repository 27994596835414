import {GarudaApi} from "./infrastructure/garudaApi";
import {ProfileSharing} from "./profileSharing";

export class ProfileCard {
    private readonly garudaApi: GarudaApi;
    private readonly profileId: string;
    private readonly shareProfileSelector: string = ".share-profile-button";

    constructor(profileId : string) {
        this.garudaApi = new GarudaApi();
        this.profileId = profileId;
    }

    public init(): void {
        const profileSharing = new ProfileSharing([this.profileId]);

        $(this.shareProfileSelector).each(function (_) {
            $(this).off("click").on("click", async () => {
                await profileSharing.openSharingModal();
            });
        });
    }
}
