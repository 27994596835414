class Localization {

  public static getText(key: string) {
    return this.getTextFromDictionary(key, this.getLocalizationResources().text);
  }

  private static getLocalizationResources() {
    return (window as any).localization;
  }

  private static getTextFromDictionary(key: string, dictionary: any) {

    const text = dictionary[key];

    if (text === undefined) {
      throw Error("Undefined key \"" + key + "\" in dictionary");
    }

    return text;
  }
}

export { Localization };
