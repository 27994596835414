export class DeleteNoteOtherUserModel {

    constructor(noteId: number, userId: string) {
        this.noteId = noteId;
        this.userId = userId;
    }

    userId: string;
    noteId: number;

}
