import {GraphSwitchEnum} from "../../models/enums/graphSwitchEnum";
import {GraphSwitchModule} from "../graph-switch-module";

export class ExpandModule {
    private expandContainerSelector: string;
    private collapseContainerSelector: string;
    private readonly graphSwitchesModule: GraphSwitchModule;

    constructor(expandContainerSelector: string,
                collapseContainerSelector: string) {
        this.graphSwitchesModule = new GraphSwitchModule({});

        this.expandContainerSelector = expandContainerSelector;
        this.collapseContainerSelector = collapseContainerSelector;
    }

    public init() {
        const self = this;
        const showGenerelInsight = this.graphSwitchesModule.getSwitchValue(GraphSwitchEnum.GenerelInsight.toString());

        if (!showGenerelInsight) {
            $(this.expandContainerSelector).closest(".card").children(".card-body").hide();
            $(self.expandContainerSelector).show();
            $(self.collapseContainerSelector).hide();
        }

        self.bindExpand();
        self.bindCollapse();
    }

    private bindExpand = () => {
        const self = this;
        $(this.expandContainerSelector).find(":button").off("click").on("click", (e) => {
            $(e.currentTarget).closest(".card").children(".card-body").show();
            $(self.expandContainerSelector).toggle();
            $(self.collapseContainerSelector).toggle();
            this.graphSwitchesModule.setSwitchValue(GraphSwitchEnum.GenerelInsight.toString(), true);
        });
    }

    private bindCollapse = () => {
        const self = this;
        $(this.collapseContainerSelector).find(":button").off("click").on("click", (e) => {
            $(e.currentTarget).closest(".card").children(".card-body").hide();
            $(self.expandContainerSelector).toggle();
            $(self.collapseContainerSelector).toggle();
            this.graphSwitchesModule.setSwitchValue(GraphSwitchEnum.GenerelInsight.toString(), false);
        });
    }
}

