import {DropdownModule, TableModule, TooltipModule} from "ditmer-embla";
import { Localization } from "../localization-module";
import { GarudaApi } from "../../infrastructure/garudaApi";
import { RespondentActions } from "./respondentActions";
import { ToolEnum } from "../../models/enums/toolEnum";
import { SubToolEnum } from "../../models/enums/subToolEnum";
import { ProfileSharing } from "../../profileSharing";
import { TransferOwnerships } from "../../transferOwnerships";
import { RespondentListConfiguration } from "./respondentListConfiguration";
import { RespondentMerge } from "../../respondentMerge";
import { CustomTooltipModule } from "../custom-tooltip-module";
import { FilterComparisonType } from "../../models/enums/filterComparisonType";
import {FilterType} from "../../models/enums/filterType";
import {profileStatusEnum} from "../../models/enums/profileStatusEnum";
import {EventLogModule} from "../../eventLogModule";

export const RespondentListFactory = (tool: ToolEnum, subtool: SubToolEnum,
                                      respondentListLoader: string, respondentListContainer: string,
                                      limit?: number, paging?: boolean,
                                      tableSelector?: string, titleSelector?: string,
                                      filterSelector?: string, tableActionsSelector?: string) => {
    return new RespondentListModule({
        tool: tool,
        subtool: subtool,
        limit: limit,
        paging: paging,
        respondentListContainer: respondentListContainer,
        respondentListLoader: respondentListLoader,
        tableActionsSelector: tableActionsSelector ?? "#responsive-table-actions",
        filterSelector: filterSelector ?? "#responsive-table-filter",
        titleSelector: titleSelector ?? "#responsive-table-title",
        tableSelector: tableSelector ?? "#respondent-table"
    });
}

export class RespondentListModule {

    private readonly _tool: ToolEnum;
    private readonly _subtool: SubToolEnum;
    private readonly _limit: number;
    private readonly _paging: boolean;

    public get tool(): ToolEnum {
        return this._tool;
    }

    public get subtool(): SubToolEnum {
        return this._subtool;
    }

    public get limit(): number {
        return this._limit;
    }

    public get respondentListContainer() {
        return this._respondentListContainer;
    }

    private readonly garudaApi: GarudaApi;
    private readonly respondentActions: RespondentActions;
    private table: DataTables.Api;

    private readonly tableSelector: string;
    private readonly titleSelector: string;
    private readonly filterSelector: string;
    private readonly tableActionsSelector: string;

    private readonly _respondentListContainer: string;
    private readonly respondentListLoader: string;

    constructor(configuration: RespondentListConfiguration) {
        this._tool = configuration.tool;
        this._subtool = configuration.subtool;
        this._limit = configuration.limit;
        this._paging = configuration.paging ?? true;
        this.tableSelector = configuration.tableSelector;
        this.titleSelector = configuration.titleSelector;
        this.filterSelector = configuration.filterSelector;
        this.tableActionsSelector = configuration.tableActionsSelector;
        this.respondentListLoader = configuration.respondentListLoader;
        this._respondentListContainer = configuration.respondentListContainer;

        this.garudaApi = new GarudaApi();
        this.respondentActions = new RespondentActions(this._tool, this._subtool);
    }

    public showList() {
        $(this.respondentListLoader).hide();
        $(this.respondentListContainer).css("opacity", 1);
    }

    public hideList() {
        $(this.respondentListLoader).show();
        $(this.respondentListContainer).css("opacity", 0);
    }

    public initTable() {
        if (this.table) {
            this.table.destroy();
        }

        const $this = this;
        const searchedName = $("#searched-name-input").data("searchedname") as string;
        const searchedRespondentType = $("#searched-respondent-type-input").data("searchedrespondenttype") as string;
        const searchedProfileSharing = $("#searched-profile-sharing-input").data("searchedprofilesharing") as string;
        const searchedRespondentExpiration: string[] = [];
        const hasLinkedFilter = searchedName || searchedRespondentType || searchedProfileSharing || searchedRespondentExpiration.length > 0;
        $(".searched-respondent-expiration-input").each(function() {
            searchedRespondentExpiration.push($(this).data("searchedrespondentexpiration"))
        })

        let filterDataFromState: any[] = [];
        const tableModule = new TableModule({
            tableSelector: this.tableSelector,
            titleSelector: this.titleSelector,
            useSelect2: false,
            filterSelector: this.filterSelector,
            responsive: true,
            paging: this._paging,
            additionalDatatableSettings: {
                stateSave: true,
                stateSaveParams: (settings, data: any) => {
                    const filters: any[] = [];
                    const $dropdowns = $(`${this.filterSelector} select`);
                    $dropdowns.each((index, element) => {
                        filters.push({
                            id: $(element).attr("id"),
                            value: $(element).val()
                        })
                    })

                    data.filters = filters;
                },
                stateLoadParams: (settings: DataTables.Settings, data: any) => {
                    filterDataFromState = data.filters;
                },
                preDrawCallback: () => {
                    if (filterDataFromState) {
                        if(!hasLinkedFilter) {
                            $(`${this.filterSelector} select`).each((index: number, select: HTMLElement) => {
                                const filterId = $(select).attr("id");

                                const filterState = filterDataFromState.find(x => x.id === filterId);
                                if (filterState) {
                                    setTimeout(() => {
                                        $(select).val(filterState.value);
                                        $(select).trigger("change");
                                    }, 100)
                                }
                            });

                            this.handleFiltersAppliedMarker();
                        }

                        filterDataFromState = null;
                    }
                },
                drawCallback: function() {
                    const table = this.api();
                    const filteredCount = table.rows({search: 'applied'}).count();
                    const total = table.rows().count();
                    const title = Localization.getText("General_XY").format(filteredCount, total);
                    $("#title-container").text(title);
                },
                initComplete: function() {
                    new DropdownModule().init(`${$this.filterSelector} select`, {
                        additionalSelect2Options: {
                            language: {
                                noResults: function () {
                                    return Localization.getText("Global_NoResult");
                                }
                            }
                        },
                        searchable: false
                    });
                }
            },
            actions: {
                actionsSelector: this.tableActionsSelector,
                selectedTextPlural: Localization.getText("Global_Selected"),
                selectedTextSingle: Localization.getText("Global_Selected")
            },
            language: {
                search: Localization.getText("Global_OptionalSearchCriteria"),
                searchPlaceholder: Localization.getText("Global_OptionalSearchCriteria"),
                zeroRecords: Localization.getText("NoProfileFound"),
            },
            filtersAsModal: {
                filtersInModal: "always",
                filterButtonText: Localization.getText("General_Filter"),
                modalTitle: Localization.getText("General_Filter"),
                modalButtonText: Localization.getText("Common_Close")
            }
        });

        this.table = tableModule.getDatatablesApi();

        const $dropdowns = $(`${this.filterSelector} select`);
        $dropdowns.on("change", (e) => {
            const $filterDropdown = $(e.target);
            const columnToFilterIndex = $filterDropdown.data("filter-column") as number;

            const $filtersOnSameColumn = $dropdowns.filter((index, dropdown) => $(dropdown).data("filter-column") === columnToFilterIndex);
            const values = $filtersOnSameColumn
                .map((i, element) => ({
                    type: $(element).data("filter-comparison-type") as string,
                    value: $(element).val() as string | string[]
                }))
                .get();

            const hasOrFilter = values
                .filter(v => v.value.length !== 0)
                .map(v => v.type)
                .filter(type => type === FilterComparisonType[FilterComparisonType.Or]).length !== 0;

            let filterValues: string[] = [];
            values.forEach(x => {
               if(Array.isArray(x.value)) {
                   x.value.forEach(value => filterValues.push(value));
               } else {
                   filterValues.push(x.value);
               }
            });

            filterValues = filterValues.filter(v => v.length !== 0);
            if(hasOrFilter && filterValues.length > 0) {
                let regex = "";
                values.filter(x => x.type !== FilterComparisonType[FilterComparisonType.Or]).forEach(x => {
                    if (Array.isArray(x.value)) {
                        x.value.forEach(value => regex += `(${value})[\\s\\S]*`);
                    } else {
                        regex += `(${x.value})[\\s\\S]*`
                    }
                })

                const orValues: string[] = [];
                values.filter(x => x.type === FilterComparisonType[FilterComparisonType.Or]).forEach(x => {
                    if (Array.isArray(x.value)) {
                        x.value.forEach(value => orValues.push(value));
                    } else {
                        orValues.push(x.value);
                    }
                })

                regex += `(${orValues.join("|")})`;
                const column = tableModule.getDatatablesApi().columns([columnToFilterIndex]);
                column.search(regex, true, false, false);
                column.draw();
            } else {
                const isStatusFilter = $filterDropdown.hasClass("status-filter");

                if (isStatusFilter && filterValues.length === 0) {
                    const column = tableModule.getDatatablesApi().columns([columnToFilterIndex]);
                    const regExAvoidArchivedStatus = "^(?!.*4).*$";
                    column.search(regExAvoidArchivedStatus, true, false, false);
                    column.draw();
                }
                else {
                    if(isStatusFilter && filterValues[0] === profileStatusEnum.All.toString())
                        filterValues = [];

                    // Replace all spaces for questionaires (The Garuda Profile => TheGarudaProfile)
                    if (columnToFilterIndex == 3)
                    {
                        filterValues = filterValues.map(value => value.replace(new RegExp(" ", 'g'), ""));
                    }

                    var filterValue = filterValues.join(" ");

                    tableModule.filterColumn(columnToFilterIndex, filterValue, false, false);
                }
            }

            this.handleFiltersAppliedMarker();
        });

        if (hasLinkedFilter) {
            this.table.search(''); // Clear primary search filter

            this.clearFilters();

            // Set query parameter values if present
            if (searchedName){
                this.table.search(searchedName).draw();
            }
            if (searchedRespondentType) {
                const applicantTypeFilter = $(`select[data-filter-type="${FilterType[FilterType.Type]}"]`);
                applicantTypeFilter.val(searchedRespondentType);
                applicantTypeFilter.trigger('change');
            }
            if (searchedProfileSharing) {
                const profileSharingFilter = $(`select[data-filter-type="${FilterType[FilterType.Sharing]}"]`);
                profileSharingFilter.val(searchedProfileSharing);
                profileSharingFilter.trigger('change');
            }
            if (searchedRespondentExpiration) {
                const respondentExpirationFilter = $(`select[data-filter-type="${FilterType[FilterType.Expiration]}"]`);
                respondentExpirationFilter.val(searchedRespondentExpiration);
                respondentExpirationFilter.trigger('change');
            }
        }

        $this.table.off("draw").on("draw", function () {
            RespondentListModule.initRespondentFeatures($this);
        });

        $this.table.off("responsive-display").on("responsive-display", function () {
            RespondentListModule.initRespondentFeatures($this);
        });

        RespondentListModule.initRespondentFeatures(this);
        this.handleFiltersAppliedMarker();
    }

    private handleFiltersAppliedMarker() {
        let counter = 0;
        $(`${this.filterSelector} select`).each((index: number, select: HTMLElement) => {
            const value = $(select).val();
            if(value && (!Array.isArray(value) || value.length !== 0)) {
                counter++;
            }
        });

        const $filterBtn = $(".filter-modal-show");
        if(counter !== 0) {
            $filterBtn.removeClass("btn-default").addClass("btn-primary");
            this.insertFiltersAppliedPill($filterBtn, counter);
        } else {
            $filterBtn.removeClass("btn-primary").addClass("btn-default");
            $("#filters-applied-pill").remove();
        }
    }

    private insertFiltersAppliedPill($filterBtn: JQuery<HTMLElement>, filtersApplied: number) {
        const filtersAppliedId = "filters-applied-pill";
        const btnText = filtersApplied === 1 ? Localization.getText("General_ActiveFilter") : Localization.getText("General_ActiveFilters");
        $(`#${filtersAppliedId}`).remove();

        const html = `<button id="${filtersAppliedId}" class="btn btn-filterapplied">${filtersApplied} ${btnText}
                            <svg class="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <use xlink:href="/dist/icons/sprite.symbol.svg#remove"></use>
                            </svg>
                        </button>`;

        $filterBtn.parent().append(html);

        $(`#${filtersAppliedId}`).off("click").on("click", () => this.clearFilters());
    }

    private clearFilters() {
        const $dropdowns = $(`${this.filterSelector} select`);
        $dropdowns.each((index: number, select: HTMLElement) => {
            if ($(select).prop("multiple")) {
                $(select).val([]);
            } else {
                $(select).val("");
            }
            $(select).trigger("change");
        });
    }

    private static initRespondentFeatures(respondentList: RespondentListModule) {
        respondentList.initNotOwnedRespondents(respondentList.table);
        respondentList.initActionRow();

        respondentList.respondentActions.initDelete(respondentList.getChosenProfileIds, respondentList.table);
        respondentList.respondentActions.initDuplicate(respondentList.getChosenProfileIds, respondentList.table);
        respondentList.respondentActions.initArchive(respondentList.getChosenProfileIds, respondentList.table);
        respondentList.respondentActions.initWithdrawConsent(respondentList.getChosenProfileIds, respondentList.table);
        respondentList.respondentActions.initEditTags(respondentList, respondentList.table);
        respondentList.respondentActions.initTransferOwnership(respondentList, respondentList.table);
        respondentList.respondentActions.initEventlogModal(respondentList);
        respondentList.respondentActions.initSharing(respondentList, respondentList.table);
        respondentList.respondentActions.initReportGeneration(respondentList.table);
        respondentList.respondentActions.initEditProfile(respondentList);
        respondentList.respondentActions.initClickableRow();
        respondentList.respondentActions.initRespondentMerging(respondentList, respondentList.table);
        respondentList.respondentActions.initCreateGroupProfile(respondentList, respondentList.table);
        respondentList.respondentActions.initGraphIQ(respondentList);

        new TooltipModule($("[data-tooltip]"));
    }

    private getChosenProfileIds(): string[] {
        const personprofileIds: string[] = [];
        const profileIdInputs = $(".profile-action-id");

        profileIdInputs.map(function () {
            personprofileIds.push($(this).val() as string);
        });

        return personprofileIds;
    }

    private initActionRow() {
        const actionRow = $(".card-body-actions-row");
        actionRow.on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", () => {
            if (actionRow.height() > 0) {
                actionRow.addClass("animationend");
            } else {
                actionRow.removeClass("animationend");
            }
        });
    }

    private initNotOwnedRespondents(table: DataTables.Api) {
        const $this = this;
        $this.toggleProfileActions(table)

        $(".datatable-select-all-checkbox").off().on("change", function () {

            const allInputChecked = $(this).prop("checked");

            if (!allInputChecked) {
                table.rows().deselect();
                $(this).prop("checked", false);
            } else {
                table.rows({search: 'applied'}).select();
                $(this).prop("checked", true);
            }

            $this.toggleProfileActions(table)
        });

        table.on("select", () => {
            $this.toggleProfileActions(table);
        })
        .on('deselect', () => {
            $this.toggleProfileActions(table);
        });
    }

    private toggleProfileActions(table: DataTables.Api) {
        const disableReports = this.resolveDisable(table, "respondent-reports", "False", 2, "True");
        const disableSharing = this.resolveDisable(table, "can-share", "False", 2, "True");
        const disableActions = this.resolveDisable(table, "respondent-owner", "False", 1, "True", true);
        const disableArchive = this.resolveDisable(table, "can-archive", "False", 1, "True", true);
        const disableMerge = this.resolveDisable(table, "can-merge", "False", 2, "True", true);
        const disableCreateGroupProfile = this.resolveDisable(table, "group-profile", "False", 2, "True");
        const disableTransfer = this.resolveDisable(table, "respondent-owner", "False", 1, "True", true);

        this.toggleProfileAction(disableSharing, ".multi-profile-sharing");
        this.toggleProfileAction(disableReports, ".generate-group-reports");
        this.toggleProfileAction(disableReports, ".generate-multiple-respondent-reports");
        this.toggleProfileAction(disableMerge, ".merge-respondents");
        this.toggleProfileAction(disableCreateGroupProfile, ".create-static-group-profile");
        this.toggleProfileAction(disableTransfer, ".transfer-respondent-ownership");

        if (disableActions) {
            $(".btn-delete-persons").addClass("disabled-button");
            $(".btn-archive-persons").addClass("disabled-button");
        } else {
            $(".btn-delete-persons").removeClass("disabled-button");
            this.toggleProfileAction(disableArchive, ".btn-archive-persons");
        }
    }

    private toggleProfileAction(toggle: boolean, selector: string) {
        if (toggle) {
            $(selector).addClass("disabled-button");
        } else {
            $(selector).removeClass("disabled-button");
        }
    }

    private resolveDisable(table: DataTables.Api, dataElement: string, trueIfAny: string, minimumSelected: number, falseIfNotAll?: string, allowOnAllRespondentList?: boolean) {
        let disable = allowOnAllRespondentList ? false : this.tool === ToolEnum.All;
        if (disable) {
            return disable;
        }

        let count = 0;
        $.map(table.rows({ selected: true }).nodes(), function (item) {
            const value = $(item).data(dataElement);
            if (value === trueIfAny || (falseIfNotAll !== undefined && value !== falseIfNotAll)) {
                disable = true;
            }
            count++;
        });

        return disable || count < minimumSelected;
    }

    public async shareSingleProfile(profileId: string): Promise<void> {
        const profileSharing = new ProfileSharing([profileId]);
        await profileSharing.openSharingModal();
    }

    public async shareMultiProfile(profileIds: string[]): Promise<void> {
        const multiProfileSharing = new ProfileSharing(profileIds, true);
        await multiProfileSharing.openSharingModal();
    }

    public async transferOwnership(respondentList: RespondentListModule, respondentId: string[], profileId: string[], modalSelector: string): Promise<void> {
        const transferOwnership = new TransferOwnerships(respondentList, respondentId, profileId, modalSelector);
        await transferOwnership.init();
        await transferOwnership.openModal();
    }

    public async openEventlog(id: string, isProfile: boolean): Promise<void> {
        const eventlog = new EventLogModule(id, isProfile);
        await eventlog.initModal();
        await eventlog.openModal();
    }

    public async mergeRespondents(respondentIds: string[]): Promise<void> {
        const respondentMerge = new RespondentMerge(respondentIds);
        await respondentMerge.init();
        await respondentMerge.openModal();
        await this.initTooltips();
    }

    public async initTooltips():Promise<void> {
        const tooltipModule = new CustomTooltipModule({
            tooltipContainerIdSelector: "#custom-tooltip-container"
        });
        tooltipModule.init();
    }
}
